<ng-container *ngIf="theme === 'default'">
  <ng-container *ngTemplateOutlet="stepper"></ng-container>
</ng-container>

<ng-container *ngIf="theme === 'material'">
  <ng-container *ngTemplateOutlet="stepper"></ng-container>
</ng-container>

<ng-container *ngIf="theme === 'mz'">
  <ng-container *ngTemplateOutlet="stepper"></ng-container>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<ng-template #stepper>
  <div>
    <ul class="frontend-stepper" [class.complete]="false" [style.display]="display">
      <li
        *ngFor="let step of steps; let i = index"
        [class.disabled]="i !== 0 && !completedSteps[i]"
        [class.active]="selectedStepIndex === i"
        (click)="selectionChanged(i)"
      >
        {{ i + 1 }}
      </li>
    </ul>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>
