import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SortingFunctionsService {
  constructor() {}

  public sortByObjectPropertyValue(
    obj1: object,
    obj2: object,
    propertyName: string
  ): -1 | 1 | 0 {
    return obj1[propertyName] < obj2[propertyName]
      ? -1
      : obj1[propertyName] > obj2[propertyName]
      ? 1
      : 0;
  }
}
