import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PaymentData } from '../+state/rep.reducer';

@Component({
  selector: 'rep-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss'],
})
export class Step1Component implements OnInit {
  form: UntypedFormGroup = new UntypedFormGroup({
    registrationNumber: new UntypedFormControl('', [Validators.required]),
    referenceNumber: new UntypedFormControl('', [Validators.required]),
  });

  _formData: PaymentData;
  get formData(): PaymentData {
    return this._formData;
  }
  @Input() set formData(newFormData: PaymentData) {
    this._formData = newFormData;
    this.form.patchValue(newFormData, { emitEvent: false });
  }
  @Input() isLoading: boolean = false;

  @Output() formChanged = new EventEmitter<{
    registrationNumber: string;
    referenceNumber: string;
  }>();

  @Output() proceed = new EventEmitter();

  _error: string;
  get error(): string {
    return this._error;
  }
  @Input() set error(newError: string) {
    this._error = newError;
  }

  constructor() {}

  ngOnInit(): void {
   
    this.form.valueChanges.subscribe(() => {
      this.formChanged.emit(this.form.getRawValue());
    });
  }

  submitForm() {
    debugger;
    this.proceed.emit();
  }
}
