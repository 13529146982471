import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UnsubscriberComponent } from '@frontend/shared/components';
import { AppStorageEnum, CoreStorageEnum } from '@frontend/shared/models';
import { AuthService, LanguagesService, EnvironmentService } from '@frontend/shared/services';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
})
export class TestComponent extends UnsubscriberComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private langService: LanguagesService,
    private environmentService: EnvironmentService,
  ) {
    super();
  }

  public ngOnInit() {
    
    this.subscriptions.add(
      this.route.queryParams.subscribe((params) => {
        const lowerParams: Params = {};
        for (const key in params) {
            lowerParams[key.toLowerCase()] = params[key];
        }
        if (lowerParams['testid']) sessionStorage.setItem(CoreStorageEnum.testId, lowerParams['testid']);

        if (lowerParams['redirecturl']) {
          setTimeout(() => { this.router.navigateByUrl(lowerParams['redirecturl']); }, 1500);               
        }
      }
    ));

    this.subscriptions.add(this.authService.logout().subscribe());
    sessionStorage.removeItem(AppStorageEnum.temp);
    sessionStorage.removeItem(AppStorageEnum.pending);
    sessionStorage.removeItem(AppStorageEnum.country);
  }
  public env() {
    if (this.environmentService.environment.clientId.endsWith('9')) return 'T1 - Test environment';
    if (this.environmentService.environment.clientId.endsWith('8')) return 'T2 - UAT environment';
    if (this.environmentService.environment.clientId.endsWith('1')) return 'Production';
  }
  public clientId() {
    return this.environmentService.environment.clientId;
  }
  public version() {
    return this.environmentService.environment.appVersion;
  }
  public testId() {
    return sessionStorage.getItem(CoreStorageEnum.testId)
      ? sessionStorage.getItem(CoreStorageEnum.testId)
      : 'null';
  }

  public navigateToTarget(...target: string[]) {

    const url = this.router.serializeUrl(
      this.router.createUrlTree([this.langService.getCurrentLang().id, ...target])
    );
    window.open(url, '_blank');
  }

  public clearTestId(): void {
    sessionStorage.removeItem(CoreStorageEnum.testId);
  }
}
