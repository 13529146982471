import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AppStorageEnum, StorageEnum } from '../models';

@Injectable()
export class LanguagesService {
  private languages: Array<{ id: string; name: string; lang: string }>;
  public activeLanguage: any;
  private englishLang: any;

  constructor(private translate: TranslateService) {
    this.englishLang = { id: 'en', name: 'english', lang: 'en_GB' };
    this.languages = [
      { id: 'en', name: 'english', lang: 'en_GB' },
      { id: 'sv', name: 'sweden', lang: 'sv_SE' },
      { id: 'no', name: 'norway', lang: 'no_NO' },
      { id: 'dk', name: 'denmark', lang: 'da_DK' },
      { id: 'fi', name: 'finland', lang: 'fi_FI' },
      { id: 'de', name: 'germany', lang: 'de_DE' },
      { id: 'lv', name: 'latvian', lang: 'lv_LV' },
      { id: 'lt', name: 'lithuanian', lang: 'lt_LT' },
      { id: 'pl', name: 'polish', lang: 'pl_PL' },
      { id: 'it', name: 'italian', lang: 'it_IT' },
      { id: 'et', name: 'estonian', lang: 'et_EE' },
      { id: 'pt', name: 'portuguese', lang: 'pt_PT' },
      { id: 'fr', name: 'france', lang: 'fr_FR' },
      { id: 'nl', name: 'netherlands', lang: 'nl_NL' },
      { id: 'es', name: 'spanish', lang: 'es_ES' },
    ];
    this.translate.setDefaultLang(this.englishLang.lang);

    const lang = sessionStorage.getItem(AppStorageEnum.storeLanguage)
      ? sessionStorage.getItem(AppStorageEnum.storeLanguage)
      : this.translate.getBrowserLang();
    this.setCurrentLang(lang);
  }

  public getList(): Array<{ id: string; name: string; lang: string }> {
    return this.languages;
  }

  public setCurrentLang(lang: string) {
    this.activeLanguage =
      this.languages.find((el) => lang === el.lang || lang === el.id) ||
      this.englishLang;
    this.translate.use(this.activeLanguage.lang);
    localStorage.setItem(
      AppStorageEnum.storeLanguage,
      this.activeLanguage.lang
    );
  }

  public getCurrentLang() {
    return this.activeLanguage;
  }
}
