import { Injectable, inject } from '@angular/core';
import {
  AuthService,
  ComplaintService,
  EnvironmentService,
  LanguagesService,
} from '@frontend/shared/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import * as RepActions from './rep.actions';
import { RepPartialState, REP_FEATURE_KEY } from './rep.reducer';

@Injectable()
export class RepEffects {

  constructor(
    private actions$: Actions,
    private store: Store<RepPartialState>,
    private environmentService: EnvironmentService,
    private authService: AuthService,
    private complaintService: ComplaintService,
    private languagesService: LanguagesService
  ) {}


  step1Login$ = createEffect((actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(RepActions.step1Login),
      withLatestFrom(this.store),
      mergeMap(([action, store]) => {
        const { referenceNumber, registrationNumber } = store[REP_FEATURE_KEY].paymentData;

        const loginData = {
          username: referenceNumber,
          reference: registrationNumber,
        };
        
        const clientId = this.environmentService.environment.clientId;
        RepActions.step1Error({ error: null })

        return this.authService
          .login(loginData.username, loginData.reference, clientId)
          .pipe(
            map((response) => {
              return RepActions.step1Completed();
            }),
            catchError((response) => {
              return of(
                RepActions.step1Error({ error: 'REP_STEP1_LOGINFAILED' })
              );
            })
          );
      })
    )
  );

  step1Success$ = createEffect((actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(RepActions.step1Success),
      map(() => RepActions.step1Completed())
    )
  );

  submitRep$ = createEffect((actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(RepActions.submitRep),
      withLatestFrom(this.store),
      mergeMap(([action, store]) => {
        const obj = this.createRepRequest(store);

        return this.complaintService.sendComplaint(obj).pipe(
          map(() => {
            return RepActions.submitRepSuccess();
          }),
          catchError((error) =>
            of(RepActions.submitRepError({ message: error }))
          )
        );
      })
    )
  );

  repFinish$ = createEffect((actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(RepActions.repFinish),
      mergeMap(() => this.authService.logout())
    )
  );

  repPrint$ = createEffect((actions$ = inject(Actions)) =>
      actions$.pipe(
        ofType(RepActions.repPrint),
        tap(() => {
          var popupWin = window.open(
            '',
            '_blank',
            'top=0,left=0,width=900px,height=930px'
          );
          popupWin.document.open();
          popupWin.document.write(
            '<html><head><title></title></head><body onload="window.print(); setTimeout(()=>{ window.close(); }, 0)">\n          ' +
              'Temporary complaint placeholder' +
              '\n        </body>\n      </html>'
          );
          popupWin.document.close();
        })
      ),
    { dispatch: false }
  );

  repAddAnotherRep$ = createEffect((actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(RepActions.addAnotherRep),
      mergeMap(() =>
        this.authService.logout().pipe(
          map(() => {
            return RepActions.setCurrentStep({ currentStep: 1 });
          })
        )
      )
    )
  );


  private createRepRequest(store: RepPartialState) {
    const { paymentData, complaintData, imagesData } = store[REP_FEATURE_KEY];
    const lang = this.languagesService.activeLanguage.lang;

    const request = {
      invoiceNo: paymentData.referenceNumber,
      vrm: paymentData.registrationNumber,
      clientId: this.environmentService.environment.clientId,
      cpr: complaintData.cpr,
      email: complaintData.email,
      address: complaintData.address,
      postalCode: complaintData.postalCode,
      city: complaintData.city,
      country: complaintData.country,
      comment: complaintData.complaint,
      images: imagesData.images,
      name: complaintData.name,
      lang,
    };

    return request;
  }
}
