<ng-container *ngIf="theme === 'default'">
  <div class="select-wrapper">
    <select
      [id]="id"
      [ngModel]="selectedValue"
      (ngModelChange)="change($event)"
    >
      <option *ngFor="let option of options" [ngValue]="option.value">
        {{ option.label | translate }}
      </option>
    </select>
    <img class="caret" src="assets/img/caret-down-fill.svg" />
  </div>
</ng-container>

<ng-container *ngIf="theme === 'material'">
  <div class="select-wrapper">
    <select
      [id]="id"
      [ngModel]="selectedValue"
      (ngModelChange)="change($event)"
    >
      <option *ngFor="let option of options" [ngValue]="option.value">
        {{ option.label | translate }}
      </option>
    </select>
    <img class="caret" src="assets/img/caret-down-fill.svg" />
  </div>
</ng-container>

<ng-container *ngIf="theme === 'mz'">
  <div class="select-wrapper">
    <select
      [disabled]="isDisabled"
      class="form-control"
      [id]="id"
      [ngModel]="selectedValue"
      (ngModelChange)="change($event)"
    >
      <option *ngFor="let option of options" [ngValue]="option.value">
        {{ option.label | translate }}
      </option>
    </select>
    <img class="caret" src="assets/img/caret-down-fill.svg" />
  </div>
</ng-container>
