<frontend-themed-layout class="dispensation-layout large">
  <div class="mt-4">
  <frontend-stepper
    [steps]="steps"
    [routeKey]="'rep'"
    [completedStepsArr]="completedSteps$ | async"
    [currentStep]="currentStep$ | async"
    (currentStepChange)="onCurrentStepChange($event)"
  >
    <router-outlet></router-outlet>
  </frontend-stepper>
</div>
</frontend-themed-layout>