import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadService {
  public isLoading = new BehaviorSubject<boolean>(false);
  public show() {
    this.isLoading.next(true);
  }
  public hide() {
    this.isLoading.next(false);
  }
  public toggle(show: boolean) {
    this.isLoading.next(show);
  }
  public hideWithDelay() {
    setTimeout(() => this.isLoading.next(false), 3500);
  }
}
