export enum requests {
  accountData = '/v1/wallet/getsavedaccounts',
  applyPayment = '/v1/wallet/deposit',
  claims = '/v1/claims',
  addCard = '/v1/wallets/accounts/',
  countries = '/v1/system/countries',
  postPayment = '/v2/wallet/payments',
  vehicleInfo = '/v1/vehicles/lez/mz/',
}

export enum uamRequests {
  password = '/v1/users/password',
  vehicles = '/v1/users/vehicles',
  validateEmail = '/v1/validators/username/',
  validateNumberPlate = '/v1/validators/vehicle/',
  validateNumberPlate2 = '/v1/validators/vehicle2/'
}
