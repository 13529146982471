<div class="modal-header d-none d-md-block">
  <h2 class="modal-title">{{ 'PP_ABOUT_US_TITLE' | translate }}
    <button type="button" class="close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
      <i class="pp-icon-close"></i>
    </button>
  </h2>
</div>
<div class="modal-body">
  <p [innerHTML]="'PP_ABOUT_US' | translate"></p>
</div>
