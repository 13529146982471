<div class="introduction d-none d-md-flex">
  <div class="introduction-wrap container">
    <h2>{{ 'PP_BANNER_TITLE1' | translate }}</h2>
    <h1>{{ 'PP_BANNER_TITLE2' | translate }}</h1>
    <div class="description">
      {{ 'PP_WHAT_WE_DO_BANNER' | translate }}
    </div>

    <div class="login-wrap d-md-none">
      <a routerLink="/login" class="btn btn-success login">
        <span>{{ 'PP_LoginHeader' | translate }}</span>
      </a>
    </div>
  </div>
</div>

<div class="introduction d-md-none" *ngIf="title">
  <div class="introduction-wrap container">
    <h2>{{ title | translate }}</h2>
  </div>
</div>

<div class="intro-wrap d-md-block d-none">
  <div class="intro">
    <div class="scroll-flag" #homenav></div>
    <ul class="nav row">
      <li class="col-4" routerLinkActive="nav-active" (click)="scrollTo(homenav)">
        <a routerLink="/support">{{ 'PP_CUSTOMER_SERVICE_TITLE' | translate }}</a>
      </li>
      <li class="col-4" routerLinkActive="nav-active" (click)="scrollTo(homenav)">
        <a routerLink="/what-we-do">{{ 'PP_WHAT_WE_DO_TITLE' | translate }}</a>
      </li>
      <li class="col-4" routerLinkActive="nav-active" (click)="scrollTo(homenav)">
        <a routerLink="/compliance">{{ 'PP_COMPLIANCE_REGULATIONS_TITLE' | translate }}</a>
      </li>
    </ul>
  </div>
</div>

<div [@fadeAnimation]="getRouterOutletState(homeOutlet)" class="outlet container">
  <router-outlet #homeOutlet="outlet"></router-outlet>
</div>
