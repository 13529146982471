<div class="container">
  <div class="row">
    <div class="col-lg-6 col-mb">
      <pp-card imgName="health">
        <pp-card-title>{{ 'PP_RULES_TITLE' | translate }}</pp-card-title>
        <pp-card-description>
          <p [innerHTML]="'PP_RULES' | translate"></p>
        </pp-card-description>
      </pp-card>
    </div>
    <div class="col-lg-6 col-mb">
      <pp-card imgName="parking">
        <pp-card-title>{{ 'PP_RULE_OF_ENFORCEMENT_TITLE' | translate }}</pp-card-title>
        <pp-card-description>
          <p [innerHTML]="'PP_RULE_OF_ENFORCEMENT' | translate"></p>
        </pp-card-description>
      </pp-card>
    </div>
    <div class="col-lg-6">
      <pp-card imgName="infra">
        <pp-card-title>{{ 'PP_PARKIAPAYS_ROLE_TITLE' | translate }}</pp-card-title>
        <pp-card-description>
          <p [innerHTML]="'PP_PARKIAPAYS_ROLE' | translate"></p>
        </pp-card-description>
      </pp-card>
    </div>
  </div>
</div>
