<div class="introduction"></div>
<div class="text-block">
  <div class="container">
    <h2>{{ 'PP_BANNER_TITLE1' | translate }}</h2>
    <h1>{{ 'PP_BANNER_TITLE2' | translate }}</h1>
    <div class="description">
      {{ 'PP_WHAT_WE_DO_BANNER' | translate }}
    </div>
    <div class="login-wrap d-md-none" *ngIf="!isLogged">
      <a routerLink="/login" class="btn btn-success login">
        <span>{{ 'PP_LoginHeader' | translate }}</span>
      </a>
    </div>
  </div>
</div>
