<ng-container *ngIf="theme === 'default'">
  <input
    [id]="id"
    [type]="type"
    [(ngModel)]="value"
    [attr.inputmode]="inputMode"
    [attr.pattern]="pattern"
    [attr.maxlength]="maxLength"
    [attr.min]="min"
    [attr.max]="max"
  />
</ng-container>

<ng-container *ngIf="theme === 'material'">
  <input
    matInput
    [id]="id"
    [type]="type"
    [(ngModel)]="value"
    [attr.inputmode]="inputMode"
    [attr.pattern]="pattern"
    [attr.maxlength]="maxLength"
    [attr.min]="min"
    [attr.max]="max"
  />
</ng-container>

<ng-container *ngIf="theme === 'mz'">
  <input
    class="form-control"
    [id]="id"
    [type]="type"
    [disabled]="isDisabled"
    [(ngModel)]="value"
    [attr.inputmode]="inputMode"
    [attr.pattern]="pattern"
    [attr.maxlength]="maxLength"
    [attr.min]="min"
    [attr.max]="max"
  />
</ng-container>
