import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { HttpTranslateFactory } from '../../helpers';

import { LoginComponent, LogoutComponent } from './index';

const COMPONENTS = [LoginComponent, LogoutComponent];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    NgbModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpTranslateFactory,
      },
    }),
  ],
})
export class AuthModule {}
