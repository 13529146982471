import { Component, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { setCurrentStep } from '../+state/rep.actions';
import { RepPartialState } from '../+state/rep.reducer';
import { getCompletedSteps, getCurrentStep } from '../+state/rep.selectors';

@Component({
  selector: 'frontend-rep-layout',
  templateUrl: './rep-layout.component.html',
  styleUrls: ['./rep-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RepLayoutComponent {
  steps: Array<{ title: string; path: string; stepIndex: number }> = [
    { title: 'Fill payment data', path: 'step1', stepIndex: 1 },
    { title: 'Fill complaint data', path: 'step2', stepIndex: 2 },
    { title: 'Add documents', path: 'step3', stepIndex: 3 },
    { title: 'Finish', path: 'step4', stepIndex: 4 },
  ];

  completedSteps$: Observable<number[]>;
  currentStep$: Observable<number>;

  constructor(public store: Store<RepPartialState>) {
    this.completedSteps$ = this.store.pipe(select(getCompletedSteps));
    this.currentStep$ = this.store.pipe(select(getCurrentStep));
  }

  onCurrentStepChange(currentStep: number) {
    this.store.dispatch(
      setCurrentStep({
        currentStep,
      })
    );
  }
}
