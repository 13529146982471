export enum ServerTypes {
  claims = 'claims',
  auth = 'auth',
}

export const servers = [
  { type: ServerTypes.claims, url: ['https://api1.parkiapay.com/v1', 'https://api2.parkiapay.com/v1', 'https://api3.parkiapay.com/v1'] },
  { type: ServerTypes.auth, url: ['https://api1.parkiapay.com', 'https://api2.parkiapay.com', 'https://api3.parkiapay.com'] },
];

export const defaultServerConfig = [
  { type: ServerTypes.claims, index: 0 },
  { type: ServerTypes.auth, index: 0 },
];

export const defaultServer = ServerTypes.claims;
