import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AboutUsComponent } from '../../pages/home/about-us/about-us.component';
import { MoreNumbersComponent } from '../../pages/home/more-numbers/more-numbers.component';

@Component({
  selector: 'pp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public today: number = Date.now();
  public version = environment.appVersion;

  constructor(private modalService: NgbModal) {}

  public openAboutUs(event) {
    event.preventDefault();
    this.modalService.open(AboutUsComponent);
  }

  public openMoreNumbers(event) {
    event.preventDefault();
    this.modalService.open(MoreNumbersComponent, {
      centered: true,
      windowClass: 'modal-width-lg',
    });
  }
}
