import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as RepActions from '../+state/rep.actions';
import { PaymentData, RepPartialState } from '../+state/rep.reducer';
import * as RepSelectors from '../+state/rep.selectors';

@Component({
  selector: 'rep-step1-container',
  templateUrl: './step1.container.html',
})
export class Step1Container implements OnInit {
  paymentData$: Observable<PaymentData>;
  step1Error$: Observable<any>;
  isLoading$: Observable<boolean>;

  constructor(private store: Store<RepPartialState>) {
    this.paymentData$ = this.store.pipe(select(RepSelectors.getPaymentData));
    this.step1Error$ = this.store.pipe(select(RepSelectors.step1Error));
    this.isLoading$ = this.store.pipe(select(RepSelectors.isLoading));
  }

  ngOnInit(): void {}

  auth() {
    
    this.store.dispatch(RepActions.step1Login());
  }

  storeFormData({ registrationNumber, referenceNumber }) {
    this.store.dispatch(
      RepActions.paymentDataChanged({
        paymentData: { registrationNumber, referenceNumber },
      })
    );
  }
}
