import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AppStorageEnum,
  countriesEU,
  Country,
  DropdownSelectedItemTypes,
  requests,
  ServerTypes,
} from '@frontend/shared/models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { DropdownService } from './dropdown.service';
import { SortingFunctionsService } from './sorting-functions.service';

@Injectable({ providedIn: 'root' })
export class InnerDropService extends DropdownService {
  private items: BehaviorSubject<DropdownSelectedItemTypes[]> = new BehaviorSubject([]);
  private extraItems: BehaviorSubject<DropdownSelectedItemTypes[]> = new BehaviorSubject([]);
  private countries: Country[];

  public items$: Observable<DropdownSelectedItemTypes[]>;
  public extraItems$: Observable<DropdownSelectedItemTypes[]>;

  constructor(
    private client: HttpClient,
    private sf: SortingFunctionsService,
    private as: ApiService
  ) {
    super(client, as);
    this.items$ = this.items.asObservable();
    this.extraItems$ = this.extraItems.asObservable();
  }

  public updateItems(arr: DropdownSelectedItemTypes[]): void {
    this.items.next(arr);
  }
  public updateExtraItems(arr: DropdownSelectedItemTypes[]): void {
    this.extraItems.next(arr);
  }

  public getEUCountries(array, convertToDropItem: boolean = true): any[] {
    let filtered = array.filter((item) => this.isEUmember(item));
    return convertToDropItem
      ? filtered.map((item) => this.convertToDropItemFormat(item))
      : filtered;
  }

  public getNotEuCountries(array) {
    return array
      .filter((item) => !this.isEUmember(item))
      .sort((a, b) => this.sf.sortByObjectPropertyValue(a, b, 'name'))
      .map((item) => this.convertToDropItemFormat(item));
  }

  public getCountries(): Observable<Country[]> {
    return this.countries && of(this.countries) ||
      this.client.get<Country[]>(this.as.getCurentServer(ServerTypes.claims) + requests.countries, { headers: this.as.getHeaders(ServerTypes.claims, 0) }).pipe(tap(countries => this.countries = countries));
  }

  public selectedEUCountry(): DropdownSelectedItemTypes {
    const country = sessionStorage.getItem(AppStorageEnum.country);
    return country
      ? countriesEU.some((item) => item.id.toUpperCase() === country.toUpperCase())
        ? DropdownSelectedItemTypes.flag
        : DropdownSelectedItemTypes.countryCode
      : DropdownSelectedItemTypes.flag;
  }
}
