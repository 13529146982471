import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable, ErrorHandler } from '@angular/core';
import { RemoteSettingsService } from './remote-settings.service';
import { ApplicationInsightsService as IApplicationInsightsService } from '@frontend/shared/interfaces';

@Injectable({ providedIn: 'root' })
export class ApplicationInsightsService implements IApplicationInsightsService {
  applicationInsights: ApplicationInsights;

  constructor(private remoteSettingsService: RemoteSettingsService) {
    this.remoteSettingsService.clientSettings$.subscribe((settings) => {
      this.applicationInsights = new ApplicationInsights({
        config: settings.applicationInsightsConfiguration,
      });

      this.applicationInsights.loadAppInsights();
    });
  }

  public trackError(error: Error) {
    this.applicationInsights.trackException({ error });
  }
}

 @Injectable()
 export class ApplicationInsightsErrorHandler extends ErrorHandler {
   constructor(private applicationInsightsService: ApplicationInsightsService) {
     super();
   }

   handleError(error: Error) {
     console.error(error);
     this.applicationInsightsService.trackError(error);
   }
 }
