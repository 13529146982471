import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AboutUsComponent,
  ComplianceComponent,
  CustomerServiceComponent,
  HomeComponent,
  MobileHomeComponent,
  MoreNumbersComponent,
  OurApproachComponent,
  WhatWeDoComponent,
  TestComponent
} from './index';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      // {path: '', redirectTo: 'customer-service', pathMatch: 'full'},
      { path: '', component: MobileHomeComponent, pathMatch: 'full' },
      { path: 'what-we-do', component: WhatWeDoComponent },
      { path: 'our-approach', component: OurApproachComponent },
      { path: 'support', component: CustomerServiceComponent },
      { path: 'compliance', component: ComplianceComponent },
      { path: 'about', component: AboutUsComponent },
      { path: 'contacts', component: MoreNumbersComponent },
      { path: 'test', component: TestComponent },
      { path: '**', redirectTo: '' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRouting {}
