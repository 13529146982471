export enum ErrorTexts {
  requiredWhen = 'ERROR_FIELD_REQUIRED',
  required = 'ERROR_FIELD_REQUIRED',
  email = 'ERROR_EMAIL_INVALID',
  passMinLength = 'ERROR_PASSWORD_LENGTH',
  mismatch = 'ERROR_PASSWORD_MATCH',
  terms = 'ERROR_ACCEPT_TOS',
  postalCode = 'ERROR_POSTALCODE_INVALID',
  emailExists = 'UAM.Exceptions.UserAlreadyExistException',
  numberPlateExists = 'UAM.Exceptions.VehicleAlreadyExistsOther',
  dePlateNumber = 'GERMAN_DELIMITER',
  minlength = 'ERROR_FIELD_MINLENGTH',
  pattern = 'ERROR_FIELD_PATTERN',
  oneOfCvrCpr = 'ERROR_FIELD_ONEOF_CVRCPR',
  atLeastOne = 'ERROR_FIELD_AT_LEAST_ONE'
}
