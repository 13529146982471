<div [formGroup]="formGroup">
  <div class="row align-items-end no-gutters">
    <div class="col-6 static-col">
      <label *ngIf="label" for="start">{{ label | translate }}</label>
      <input type="text" class="form-control" formControlName="dateStart" frontendDateValAccessor id="start" #start
        (focus)="start.blur()">
    </div>
    <div class="col-6 static-col">
      <div class="input-holder">
        <input type="text" class="form-control" formControlName="dateEnd" frontendDateValAccessor id="end" #end
          (focus)="end.blur()" title="end" autocomplete="end">
      </div>
    </div>
    <div class="col-12 static-col">
      <ngb-datepicker #d (dateSelect)="onDateSelection($event)" [dayTemplate]="t" [footerTemplate]="foot" class="form-control datepicker-border datepicker-custom"
          [displayMonths]="2" [navigation]="'arrows'" [showWeekNumbers]="true" [outsideDays]="'hidden'" [startDate]="firstValidDate" [minDate]="firstValidDate" [maxDate]="lastValidDate">
        </ngb-datepicker>
    </div>
    <div class="col-12 static-col mt-2" *ngIf="showTime">
      <div class="card-body d-flex pt-0 justify-content-around align-items-center">
        <ngb-timepicker [formControlName]="'timeStart'" [size]="'small'"></ngb-timepicker>
        <ngb-timepicker [formControlName]="'timeEnd'" [size]="'small'"></ngb-timepicker>
      </div>
    </div>
  </div>
</div>

<ng-template #t let-date let-focused="focused" let-disabled="disabled" let-today="today">
  <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.today]="today"
    [class.faded]="isHovered(date) || isInside(date)" [class.text-muted]="disabled"
    (mouseenter)="hoveredDate = (!startDate || date.before(countLastAvailableDate(startDate))) ? date : countLastAvailableDate(startDate)"
    (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>

<ng-template #foot>
  <div *ngIf="showFooter" class="resetButton">
    <button *ngIf="!!startDate" type="button" class="btn btn-link d-flex ml-auto" (click)="clearSelection()">Clear</button>
  </div>
</ng-template>

