import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseLanguagePicker } from './base-language-picker.component';

@Component({
  selector: 'frontend-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagePickerComponent extends BaseLanguagePicker {}
