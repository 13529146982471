<div class="container">

  <div class="row">
    <div class="column col-lg-4 col-md-6 col-12 hours">
      <h5>{{ 'PP_CUSTOMER_HOURS' | translate }}</h5>
      <table class="table">
        <tr>
          <td class="description">{{ 'PP_MONDAY_THURSDAY' | translate }}</td>
          <td class="value">{{ 'PP_AM_PM1' | translate }}</td>
        </tr>
        <tr>
          <td class="description">{{ 'PP_FRIDAY' | translate }}</td>
          <td class="value">{{ 'PP_AM_PM2' | translate }}</td>
        </tr>
        <tr>
          <td class="description">{{ 'PP_SATURDAY_SUNDAY' | translate }}</td>
          <td class="value">{{ 'PP_CLOSED' | translate }}</td>
        </tr>
        <tr>
          <td class="description">{{ 'PP_BANK_HOLIDAYS' | translate }}</td>
          <td class="value">{{ 'PP_CLOSED' | translate }}</td>
        </tr>
      </table>
    </div>


    <div class="column col-lg-4 col-md-6 col-12">
      <h5>{{ 'PP_OUR_CONTACT_DETAILS' | translate }}</h5>
      <table class="table">
        <tr>
          <td class="description">{{ 'PP_INTERNATIONALLY' | translate }}</td>
          <td class="value" [innerHTML]="'PP_PHONE_NUMBER_INTERNATIONAL' | translate"></td>
        </tr>
        <tr>
          <td class="description">{{ 'PP_EMAIL' | translate }}</td>
          <td class="value" [innerHTML]="'PP_EMAIL2' | translate"></td>
        </tr>
      </table>
      <div class="mt-3 more-buttons">
        <a class="d-none d-md-block" (click)="openMoreNumbers($event)">{{ 'PP_SHOW_MORE_NUMBERS' | translate }}</a>
        <a class="d-md-none d-block" routerLink="contacts">{{ 'PP_SHOW_MORE_NUMBERS' | translate }}</a>
      </div>
    </div>

    <div class="column col-lg-4 col-md-6 col-12 info">
      <h5>{{ 'PP_PARKTRADE_EUROPE' | translate }}</h5>
      <table class="table">
        <tr>
          <td class="description">{{ 'PP_ADDRESS' | translate }}</td>
          <td class="value">{{ 'PP_ADDRESS2' | translate }}</td>
        </tr>
        <tr>
          <td class="description">{{ 'PP_ORGNUMBER' | translate }}</td>
          <td class="value">{{ 'PP_ORGNUMBER2' | translate }}</td>
        </tr>
      </table>
      <div class="mt-3 d-lg-none">
        <a class="d-none d-md-block" (click)="openAboutUs($event)">{{ 'PP_ABOUT_US_TITLE' | translate }}</a>
        <a class="d-block d-md-none" routerLink="about">{{ 'PP_ABOUT_US_TITLE' | translate }}</a>
      </div>
      <div class="mt-3">
        <div [innerHTML]="'PP_PRIVACY_POLICY' | translate"></div>
      </div>
    </div>
  </div>


</div>
<div class="copyright text-center">
  <div>Copyright © 2005-{{ today | date: 'yyyy' }}. All rights reserved, version {{ version }}</div>
</div>
