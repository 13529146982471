import { Component, Input } from '@angular/core';
import { BaseThemedComponent } from '../base-themed.component';

@Component({
  selector: 'frontend-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent extends BaseThemedComponent {
  @Input() for: string;
}
