import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormThemedComponent } from '../base-form-themed.component';

@Component({
  selector: 'frontend-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
})
export class TextInputComponent extends BaseFormThemedComponent {
  @Input() id: string;
  @Input() type: string = 'text';
  @Input() characters: number;
  @Input() inputMode: string;
  @Input() pattern: string;
  @Input() maxLength: number;
  @Input() min: string;
  @Input() max: string;
}
