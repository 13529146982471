import { Directive, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { BaseThemedComponent } from './base-themed.component';

@Directive()
export class BaseFormThemedComponent
  extends BaseThemedComponent
  implements ControlValueAccessor {
  _value: any;
  get value() {
    return this._value;
  }
  @Input() set value(newValue) {
    this._value = newValue;
    this.onChange(newValue);
  }

  isDisabled: boolean = false;

  onChange = (event) => {};
  onTouched = () => {};

  writeValue(value: any) {
    this._value = value; // omit setter due to looped onChange call (onChange calls writeValue)
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
