import { Directive, Input, HostBinding } from '@angular/core';
import { BaseThemedDirective } from '../base-themed.directive';

const themedErrorClasses = {
  mz: 'border border-danger',
  default: '',
};

@Directive({
  selector: '[markWithError]',
})
export class MarkWithErrorDirective extends BaseThemedDirective {
  private _markWithError: boolean = false;
  get markWithError(): boolean {
    return this._markWithError;
  }
  @Input() set markWithError(markWithError: boolean) {
    this._markWithError = markWithError;
  }

  @HostBinding('class') get elementClass(): string {
    const errorClass = themedErrorClasses[this.theme];
    return this.markWithError && errorClass ? errorClass : '';
  }
}
