import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { HttpTranslateFactory } from '../../helpers';
import { HomeRouting } from './home.routing';

import { ComponentsModule } from '../../components/components.module';
import {
  AboutUsComponent,
  ComplianceComponent,
  CustomerServiceComponent,
  HomeComponent,
  MobileHomeComponent,
  MoreNumbersComponent,
  OurApproachComponent,
  WhatWeDoComponent,
} from './index';

const COMPONENTS = [
  AboutUsComponent,
  ComplianceComponent,
  CustomerServiceComponent,
  HomeComponent,
  MobileHomeComponent,
  MoreNumbersComponent,
  OurApproachComponent,
  WhatWeDoComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    NgbModule,
    CommonModule,
    TranslateModule.forChild({
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpTranslateFactory,
      },
    }),
    HomeRouting,
    ComponentsModule,
  ],
  providers: [NgbActiveModal],
})
export class HomeModule {}
