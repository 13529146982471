<header class="container navbar navbar-expand-md navbar-light">
  <div class="logo-xs navbar-brand">
    <a routerLink="/" class="logo-wrap">
      <div class="logo"></div>
    </a>
  </div>

  <div class="d-none d-md-flex collapse navbar-collapse" id="navbarToggler">
    <div class="header-menu">
      <div class="about d-none d-lg-inline-flex">
        <div class="btn btn-default" (click)="openAboutUs($event)">{{ 'PP_ABOUT_US_TITLE' | translate }}</div>
      </div>
      <pp-languages class="d-flex"></pp-languages>
      <div class="ticket-wrap" *ngIf="!isLogged">
        <a routerLink="/login" class="btn btn-success ticket d-inline-flex">
          <span>{{ 'PP_LoginHeader' | translate }}</span>
        </a>
      </div>
      <div class="account-data" *ngIf="isLogged">
        <div><a routerLink="/account">{{ 'PP_PlateNumber' | translate }}</a></div>
        <div class="licencePlate"> {{ user }}</div>
        <div class="exit" routerLink="logout">
          <i class="pp-icon-exit"></i>
          <span>{{ 'PP_Exit' | translate }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="d-md-none d-inline-flex">
    <pp-languages (toggleLang)="toggleLang($event)"></pp-languages>

    <button class="navbar-toggler" (click)="toggleMobileMenu($event)">
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>

</header>

<div class="d-md-none header-md-menu" [class.show]="isMobileMenuShow">
  <ul class="list list-group">
    <li class="list-item" routerLinkActive="nav-active" (click)="closeMobileMenu($event)">
      <a routerLink="/support">{{ 'PP_CUSTOMER_SERVICE_TITLE' | translate }}</a>
    </li>
    <li class="list-item" routerLinkActive="nav-active" (click)="closeMobileMenu($event)">
      <a routerLink="/what-we-do">{{ 'PP_WHAT_WE_DO_TITLE' | translate }}</a>
    </li>
    <li class="list-item" routerLinkActive="nav-active" (click)="closeMobileMenu($event)">
      <a routerLink="/compliance">{{ 'PP_COMPLIANCE_REGULATIONS_TITLE' | translate }}</a>
    </li>
    <li class="list-item" routerLinkActive="nav-active" (click)="closeMobileMenu($event)">
      <a routerLink="/about">{{ 'PP_ABOUT_US_TITLE' | translate }}</a>
    </li>
  </ul>
  <div class="login" *ngIf="!isLogged">
    <a routerLink="/login" class="btn btn-success ticket" (click)="closeMobileMenu($event)">
      <span>{{ 'PP_LoginHeader' | translate }}</span>
    </a>
  </div>
</div>

<div class="d-md-none">
  <div class="account-data" *ngIf="isLogged">
    <div class="licencePlate" routerLink="/account"> {{ user }}</div>
    <div class="exit" routerLink="logout">
      <i class="pp-icon-exit"></i>
      <span>{{ 'PP_Exit' | translate }}</span>
    </div>
  </div>
</div>
<hr/>

