<ng-container *ngIf="theme === 'default'">
  <input [id]="id" type="tel" [(ngModel)]="value" />
</ng-container>

<ng-container *ngIf="theme === 'material'">
  <input matInput [id]="id" type="tel" [(ngModel)]="value" />
</ng-container>

<ng-container *ngIf="theme === 'mz'">
  <input class="form-control" [id]="id" type="tel" [(ngModel)]="value" />
</ng-container>
