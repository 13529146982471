import { Injectable } from '@angular/core';
import { AppStorageEnum, StatusInvoice } from '@frontend/shared/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PaymentService {
  private paymentData: BehaviorSubject<any> = new BehaviorSubject(
    JSON.parse(sessionStorage.getItem(AppStorageEnum.paymentData))
  );
  private invoicesArray: BehaviorSubject<any> = new BehaviorSubject(null);

  public paymentData$: Observable<any>;
  public invoicesArray$: Observable<any>;

  constructor() {
    this.paymentData$ = this.paymentData.asObservable();
    this.invoicesArray$ = this.invoicesArray.asObservable();
  }

  public updatePaymentData(data) {
    sessionStorage.setItem(AppStorageEnum.paymentData, JSON.stringify(data));
    this.paymentData.next(data);
  }

  public updateStatus(arr) {
    const curVal = this.invoicesArray.value.map((item) => {
      const itemIndex = arr.indexOf(item.referenceNumber);
      return itemIndex !== -1 ? { ...item, status: StatusInvoice.PAID } : item;
    });
    this.invoicesArray.next(curVal);
  }

  public updateInvoicesArray(arr) {
    this.invoicesArray.next(arr);
  }

  public resetData() {
    sessionStorage.removeItem(AppStorageEnum.paymentData);
    this.paymentData.next(null);
    this.invoicesArray.next(null);
  }
}
