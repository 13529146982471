import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseThemedComponent } from '../base-themed.component';

export interface RadioItem {
  id: string;
  label: string;
  value: string;
}

@Component({
  selector: 'frontend-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioInputComponent),
      multi: true,
    },
  ],
})
export class RadioInputComponent
  extends BaseThemedComponent
  implements ControlValueAccessor {
  @Input() radios: Array<RadioItem>;

  onChange: Function;
  onTouched: Function;

  value: any;

  writeValue(value: string | number | boolean) {
    if (value !== this.value) {
      this.value = value;
    }
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  change(value: string | number | boolean) {
    this.value = value;
    this.onChange(value);
    this.onTouched(value);
  }
}
