import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as RepActions from '../+state/rep.actions';
import { ComplaintData, RepPartialState } from '../+state/rep.reducer';
import * as RepSelectors from '../+state/rep.selectors';

@Component({
  selector: 'rep-step2-container',
  templateUrl: './step2.container.html',
})
export class Step2Container implements OnInit {
  complaintData$: Observable<ComplaintData>;

  constructor(private store: Store<RepPartialState>) {
    this.complaintData$ = this.store.pipe(
      select(RepSelectors.getComplaintData)
    );
  }

  ngOnInit(): void {}

  goToNextStep() {
    this.store.dispatch(RepActions.step2Completed());
  }

  formChanged({
    cpr,
    email,
    address,
    postalCode,
    city,
    country,
    complaint,
    name,
  }: {
    cpr: string;
    email: string;
    address: string;
    postalCode: string;
    city: string;
    country: string;
    complaint: string;
    name: string;
  }) {
    this.store.dispatch(
      RepActions.complaintDataChanged({
        complaintData: { cpr, email, address, postalCode, city, country, complaint, name },
      })
    );
  }
}
