import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { AppReadyState, AppReadyService } from './app-ready.service';
import { filter } from 'rxjs/operators';

export interface RemoteSettings {
  clientId: string | number;
  settingsUrl: string;
}

export interface ClientSettings {
  langUrl: string;
  configVersion: string;
  clientId: string;
  adyenKey: string;
  payment: string; //  'adyencse' | 'adyenchk'
  testId: string;
  logoSrc: string;
  clientKey: string;
  title: string;
  applicationInsightsConfiguration: {};
  additionalStylesheetsUrls: Array<string>;
  currencySettings: CurrencySettings;
  faviconUrl: string;
  defaultRoute: string;
  receiptLogoSrc: string;
}

export type CurrencySettings = {
  // e.g. EUR/USB/GBP
  [currencyCode: string]: {
    // e.g. en_GB/en_US/da_DK or fallback default
    [language: string]: {
      format: string; // e.g. "{amount} $", "£ {amount}", "kr. {amount}"
    };
  };
};

export const clients: { [name: string]: RemoteSettings } = {
  'sbf-test': {
    clientId: 16089,
    settingsUrl:
      'https://d18oow8b9b9oyq.cloudfront.net/frontend/1608/sbf-test.json',
  },
  'sbf-uat': {
    clientId: 16088,
    settingsUrl:
      'https://d18oow8b9b9oyq.cloudfront.net/frontend/1608/sbf-uat.json',
  },
  'pay.storebaelt.dk': {
    clientId: 16081,
    settingsUrl:
      'https://d18oow8b9b9oyq.cloudfront.net/frontend/1608/sbf-prod.json',
  },
  local: {
    clientId: 16089,
    settingsUrl: '/assets/sbf-local.json',
    // settingsUrl: 'http://localhost:4200/assets/nsl-local.json',
  },
  'nsl-test': {
    clientId: 31009,
    settingsUrl: 'https://nsl-test.azurewebsites.net/assets/nsl-test.json',
  },
};

export function initializeRemoteSettings(
  remoteSettingsService: RemoteSettingsService
) {
  return (): Promise<any> => {
    return remoteSettingsService.init((clientSettings) => {
      loadAdditionalStylesheets(clientSettings);
      setFavicon(clientSettings);
    });
  };
}

function setFavicon(settings: ClientSettings) {
  if (settings.faviconUrl) {
    const faviconLink = window.document.querySelector<HTMLLinkElement>(
      "link[rel~='icon']"
    );
    faviconLink.href = settings.faviconUrl;
  }
}

function loadAdditionalStylesheets(settings: ClientSettings) {
  if (settings.additionalStylesheetsUrls) {
    let index: number = 0;
    const head = window.document.getElementsByTagName('head')[0];
    settings.additionalStylesheetsUrls.forEach((stylesheetUrl) => {
      const style = window.document.createElement('link');
      style.id = `dynamic-stylesheet-${index}`;
      style.href = stylesheetUrl;
      style.rel = 'stylesheet';
      head.appendChild(style);
      index++;
    });
  }
}

@Injectable()
export class RemoteSettingsService {
  private clientSettingsSubject = new BehaviorSubject<ClientSettings>(null);
  public clientSettings: ClientSettings;
  public clientSettings$: Observable<
    ClientSettings
  > = this.clientSettingsSubject
    .asObservable()
    .pipe(filter((settings) => settings !== null));
  private remoteSettings: RemoteSettings;
  isInitialized: boolean = false;

  constructor(
    private http: HttpClient,
    private appReadyService: AppReadyService
  ) {}

  async init(afterInit: (settings: ClientSettings) => void) {
    if (this.isInitialized) return;

    this.remoteSettings = this.tryGetRemoteSettings();

    if (this.remoteSettings) {
      await this.loadClientSettings();
      this.isInitialized = true;
      afterInit(this.clientSettings);

      this.appReadyService.reportReady(AppReadyState.REMOTE_SETTINGS);
    }
  }

  getLocation(): string {
    return window.location.toString();
  }

  tryGetRemoteSettings(): RemoteSettings | null {
    let chosenClientKey: string;
    const windowLocation = this.getLocation();
    Object.keys(clients).forEach((clientKey) => {
      if (windowLocation.includes(clientKey)) {
        chosenClientKey = clientKey;
        return;
      }
    });

    return clients[chosenClientKey] ? clients[chosenClientKey] : null;
  }

  async loadClientSettings() {
    const settings = await this.http
      .get<ClientSettings>(this.remoteSettings.settingsUrl)
      .toPromise();

    this.clientSettings = settings;
    this.clientSettingsSubject.next(this.clientSettings);
  }
}
