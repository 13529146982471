import { HttpClient } from '@angular/common/http';
import {
  EnvironmentService,
  AppReadyService,
  AppReadyState,
} from '@frontend/shared/services';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

export function HttpTranslateFactory(
  http: HttpClient,
  environmentService: EnvironmentService,
  appReadyService?: AppReadyService
) {
  let langUrl = environmentService.environment.langUrl;
  const defaultLang = environmentService.environment.defaultLang;

  const url = `${langUrl}/`;
  return new CustomTranslateHttpLoader(
    http,
    appReadyService,
    url,
    '.json',
    defaultLang
  );
}

class CustomTranslateHttpLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private appReadyService?: AppReadyService,
    public prefix: string = '/assets/i18n/',
    public suffix: string = '.json',
    public defaultLang: string = 'en_GB'
  ) {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<Object> {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(
      catchError(() => {
        return this.http
          .get(`${this.prefix}${this.defaultLang}${this.suffix}`)
          .pipe(catchError(() => of([])));
      }),
      tap((_) => {
        this.appReadyService?.reportReady(AppReadyState.TRANSLATION);
      })
    );
  }
}
