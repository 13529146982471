<frontend-card-page id="rep-step1" [title]="'REP_STEP1_HEADER' | translate">
  <div content fxLayout="column" class="content">
    <p fxFlex="0 1 auto">
      {{ 'REP_STEP1_TEXT' | translate }}
    </p>
    <div class="form-wrapper">
      <frontend-form [formGroup]="form" fxLayout="column" fxFlex="1 1 auto">
        <frontend-form-group>
          <frontend-label for="reference-number">{{
            'REP_REFERENCE' | translate
          }}</frontend-label>
          <frontend-text-input
            type="text"
            id="reference-number"
            formControlName="referenceNumber"
            characters="10"
          >
          </frontend-text-input>
        </frontend-form-group>

        <frontend-form-group>
          <frontend-label for="registration-number">{{ 'REP_NUMBERPLATE' | translate }}</frontend-label>
          <frontend-text-input
            type="text"
            id="registration-number"
            formControlName="registrationNumber"
            characters="10"
          >
          </frontend-text-input>
        </frontend-form-group>

        <frontend-button [disabled]="!form.valid || isLoading" (click)="submitForm()" type="button">
          <ng-container *ngIf="!isLoading">{{
            'REP_NEXT' | translate
            }}</ng-container>
          <frontend-spinner *ngIf="isLoading"></frontend-spinner>
        </frontend-button>

        <p class="mt-3 rep-step1-error" style="color: red" *ngIf="error" [innerHtml]="error | translate"></p>
      </frontend-form>
    </div>
  </div>
</frontend-card-page>
