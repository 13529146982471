export class Multimedia {
  constructor(public imgSource: string, public fileName: string, public content: string, public size: number = 0, public description?: string) { }

  static fromMediaFile(file: MediaFile): Multimedia {
    let imageSrc: string = (file.content.indexOf('data:application/pdf') === 0 || file.content.indexOf('data:text/plain') === 0)
      ? multiMediaIcon
      : file.content;
    return new this(imageSrc, file.fileName, file.content, file.size, file.description);
  }
}


/* New Multimedia object cleared of duplicate content */
export class MediaFile {
  constructor(public fileName: string, public content: string, public size: number = 0, public description?: string) { }

  static fromMultiMedia(multi: Multimedia): MediaFile {
    return new this(multi.fileName, multi.content, multi.size, multi.description);
  }
}


export const multiMediaIcon: string =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAAC0CAYAAACqnKHoA' +
    'AAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAZdEVYdFNvZnR' +
    '3YXJlAEFkb2JlIEltYWdlUmVhZHlxyWU8AAADJGlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja' +
    '2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWx' +
    'uczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4zLWMwMTEgNjYuMTQ1N' +
    'jYxLCAyMDEyLzAyLzA2LTE0OjU2OjI3ICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly9' +
    '3d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphY' +
    'm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJ' +
    'odHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlL' +
    'mNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3N' +
    'ob3AgQ1M2IChNYWNpbnRvc2gpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkZGNEYyMUI3OUFGMzExR' +
    'TM5NDcxRERBQkFFMUJBQzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkZGNEYyMUI4OUFGMzExRTM' +
    '5NDcxRERBQkFFMUJBQzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5pa' +
    'WQ6RkY0RjIxQjU5QUYzMTFFMzk0NzFEREFCQUUxQkFDMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ' +
    '6RkY0RjIxQjY5QUYzMTFFMzk0NzFEREFCQUUxQkFDMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmO' +
    'lJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6tCBTqAAAFe0lEQVR4Xu3dbVMayQKG4SY' +
    'BBXwXk2zt//9lp/ZUTmKM2SRqFNSlcfbUbmUXAVF58LqqUk6TLwmVO909wwyt0y8XtwWI9Kr5CQQSMAQTM' +
    'AQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQ' +
    'TMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMAQTMARrnX65uG2OWcDNzW35dnZWzs8vy' +
    'uj6utzervbb+fr169LptEt3Y6P0et3Sabeb3yGRgB9gOBqV40+fJ+Gm2uh0ys72Vtnq95pXSCLgBdWZ9v3' +
    'H4zIa5cb7V3UmPtjfLd3NzeYVEtgDL+jb2fnaxFvV1cTH8Wri9PevzSskEPCC6p53HX37flaOT05Xfi/PH' +
    'QEvKHnfe5+LHz/Kp89fRBxAwPyju4jNxKvOSawF/fb+Q7m5uWlGf9dqtcrmRqcZPb2r4ehf/2zzqie13gw' +
    'OJn8nVo+AFzQt4Hpp5pe3R83o6X38dFJ+XF41o5/t7+5MTsJdz7gNqBEfjSN+JeKVYwn9Au3ubJdf370pO' +
    '1v95pXpflxeluPxfwr1QyusFgG/UHVJfLC/N4l5FpdXw3J8IuJVI+AXri6n9+aIuC7Pl7W/5uEETNkbR7y' +
    '/t9OMprsaDssHEa8MATOxu71dDvZ2m9F0w+FoEvGsJ8F4PALm/+pNDYfjffEs7iL+LOJnJmD+ZnurXwYHs' +
    '0U8Gon4uQmYn2z1++XocL8ZTVcj/t/xyVrd2JFEwPyjfq83jni2T2DVGbjuiUX89ATMv+r3unNHXPfGPB0' +
    'BM1WvO/tnoUX89ATMve5uaDicKeJ6fbhGXK8X8/gEzEy6mxvl7dFhefVqtojrJ7aurkT82ATMzDY3xhEPB' +
    'jNGfFs+noj4sQmYuWxsdMYzcY34/n86f0ZsT/x4BMzc6v3O7+aI2JM9Ho+AWUh9OPwvbwaTB8Xfpz7x8uu' +
    '3782IZRIwC2u325OZeJaIv34/m5zcYrkEzIO026/Lu/FMXGOepi6h62N8WC4B82Dt8Qz87uhw8nOaMwEvn' +
    'YBZirqMHtxzA0R9lrYPeCyXgFmaep24/pqmPiCP5fFY2QUlP1b2OdVvQRwczHarIvczA/Okrq+diV4mAa+h' +
    'WW46eD4WfMsk4DXUGS/heRkEvIZ82/7LIeA1VL9tf9ZvXCCbgNdU/cYFEa8/l5EWtMqXkf6q3khwdn5Rhs' +
    'Phk90RNO0S1t2DAQbNiIcS8IJSAn4O//nv++boZwJeLktoCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZg' +
    'CCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCC' +
    'ZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCCZgCNY6/XJx2xwzh9/efyg3' +
    'NzfNiFl1NzfK26NBM+KhzMAQTMALarWaA+bS8sYtlYAX1Gl3miPm0el435ZJwAva3uo1R8xjq+99WyYBL6' +
    'jf65Vud7MZMYvdne3xyqXdjFgGAT/Am8ODccjdZsQ0Nd793Z1mxLK4jLQEl5dX5fziogyvr0u59Xb+qZ6w' +
    'qnveumw28z4OAUMwS2gIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJm' +
    'AIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAI' +
    'JmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmAIJmCIVcofFuqM8rM3P74AAAAASUVORK5CYII=';