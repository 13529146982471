<ng-container *ngIf="theme === 'default'">
  <input [id]="id" type="checkbox" [(ngModel)]="value" />
  <label [for]="id"
    ><ng-container *ngTemplateOutlet="content"></ng-container
  ></label>
</ng-container>

<ng-container *ngIf="theme === 'material'">
  <mat-checkbox [id]="id" [(ngModel)]="value"
    ><ng-container *ngTemplateOutlet="content"></ng-container
  ></mat-checkbox>
</ng-container>

<ng-container *ngIf="theme === 'mz'">
  <div style="display: flex; align-items: center; padding-right: 1rem">
    <input
      [id]="id"
      type="checkbox"
      [(ngModel)]="value"
      style="width: 1rem; height: 1rem; margin-right: 10px"
    />
    <label [for]="id" style="margin: 0"
      ><ng-container *ngTemplateOutlet="content"></ng-container
    ></label>
  </div>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
