<ng-container *ngIf="theme === 'default'">
  <button [disabled]="disabled" (click)="buttonClicked()">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="theme === 'material'">
  <button
    [disabled]="disabled"
    (click)="buttonClicked()"
    mat-flat-button
    color="primary"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="theme === 'mz'">
  <button
    class="btn btn-success btn-lg d-flex w-100 align-items-center justify-content-center"
    [disabled]="disabled"
    (click)="buttonClicked()"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
