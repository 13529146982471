import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Multimedia, multiMediaIcon } from '@frontend/shared/models';

@Component({
  selector: 'frontend-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit {
  @Output() notify: EventEmitter<Multimedia> = new EventEmitter<Multimedia>();
  @Output() remove: EventEmitter<Multimedia> = new EventEmitter<Multimedia>();
  @Output() imagesChanged: EventEmitter<Multimedia[]> = new EventEmitter<Multimedia[]>();

  @Input() description: string = null;
  @Input() preloadedFiles: Multimedia[];
  @Input() uploadButtonText: string = '';
  @Input() uploaderText: string = '';
  @Input() fileInputId: string = 'fileInputDrag';

  // max set to zero equals no limit/disabled.
  @Input() maxFileSizeKB: number = 0;
  @Input() maxNumFiles: number = 0;
  @Input() maxTotalFileZiseKB: number = 0;

  activeColor: string = 'green';
  baseColor: string = '#ccc';

  dragging: boolean = false;
  imageSrc: string = '';
  imageFileName: string = '';
  imageSize: number = 0;

  images: Multimedia[] = [];

  
  isEdgeWorkaround: boolean = false;

  constructor() {}

  ngOnInit() {
    this.images = [...this.preloadedFiles];
  }

  get multi(): boolean {
    return false;
  }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e: any) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  // TODO: there is no reason why this function can't handle multiple files and no reason to bind fileName and imgSrc to 'this'
  handleInputChange(e: any) {
    
    if(this.maxNumFiles > 0 && this.images.length >= this.maxNumFiles) {
      alert(`Can not upload more than ${this.maxNumFiles} files.`);
      return;
    }

    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    if (file != undefined) {
      const pattern = /.pdf|.jpg|.jpeg|.png|.bmp|.gif*/;

      if (!file.name.toLowerCase().match(pattern)) {
        alert('File type not supported.');
        return;
      }

      if(this.maxFileSizeKB > 0 && file.size > this.maxFileSizeKB * 1024) {
        alert(`File size must be below ${Math.floor(this.maxFileSizeKB / 1024)}MB.`);
        return;
      }

      if(this.maxTotalFileZiseKB > 0 && this.images.reduce((sum, f) => sum + f.size, file.size) >= this.maxTotalFileZiseKB * 1024) {
        alert(`Total file size must be below ${Math.floor(this.maxTotalFileZiseKB / 1024)}MB.`);
        return;
      }

      this.imageFileName = file.name;
      this.imageSize = file.size;

      const reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(file);
    }
  }

  removeAttachment(event: any, multimedia: Multimedia) {
    event.preventDefault();
    this.remove.emit(multimedia);
    let imageIndex = this.images.findIndex(
      (img) => img.fileName === multimedia.fileName
    );

    if (imageIndex > -1) {
      this.images.splice(imageIndex, 1);
      this.imagesChanged.emit(this.images);
    }
  }

  _handleReaderLoaded(e: any) {
    let fileContent: string;
    const reader = e.target;

    this.imageSrc = reader.result;

    if (
      reader.result.indexOf('data:application/pdf') === 0 ||
      reader.result.indexOf('data:text/plain') === 0
    ) {
      this.imageSrc = multiMediaIcon;
    }

    fileContent = reader.result;

    let imgData: Multimedia = new Multimedia(
      this.imageSrc,
      this.imageFileName,
      fileContent,
      this.imageSize,
      this.description
    );

    this.notify.emit(imgData);
    this.images.push(imgData);
    this.imagesChanged.emit(this.images);
  }
}
