import { createAction, props } from '@ngrx/store';
import { ComplaintData, ImagesData, PaymentData } from './rep.reducer';

export const imagesDataChanged = createAction('[Rep] Images data changed', props<{ imagesData: ImagesData }>() );

export const step1Login = createAction('[Rep] Step 1 login');

export const step1Success = createAction('[Rep] Step 1 success');

export const step1Error = createAction('[Rep] Step 1 login error', props<{ error: string }>());

export const step1Completed = createAction('[Rep] Step 1 completed');

export const step2Completed = createAction('[Rep] Step 2 completed');

export const step3Completed = createAction('[Rep] Step 3 completed');

export const submitRep = createAction('[Rep] Submit rep');

export const submitRepSuccess = createAction('[Rep] Submit rep success');

export const submitRepError = createAction('[Rep] Submit rep error', props<{ message: string }>());

export const repFinish = createAction('[Rep] Exemption finish');

export const repPrint = createAction('[Rep] Rep print');

export const setCurrentStep = createAction('[Rep] Set current step', props<{ currentStep: number }>());

export const paymentDataChanged = createAction('[Rep] Payment data changed', props<{ paymentData: PaymentData }>());

export const complaintDataChanged = createAction('[Rep] Complaint data changed', props<{ complaintData: ComplaintData }>());

export const addAnotherRep = createAction('[Rep] Add another rep');
