import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbTimeAdapter } from "@ng-bootstrap/ng-bootstrap";
import { NgbTimeStringAdapter } from "./timepicker-adapter";
import { NgbDateStringAdapter } from "./datepicker-adapter";


@Component({
	selector: "frontend-range-date-picker",
	templateUrl: "./range-date-picker.component.html",
	styleUrls: ["./range-date-picker.component.scss"],
	providers: [
		{
			provide: NgbTimeAdapter,
			useClass: NgbTimeStringAdapter,
		},
		// {
		// 	provide: NgbDateAdapter,
		// 	useClass: NgbDateStringAdapter
		// }
	],
})

export class RangeDatePickerComponent implements OnInit {
	
	public showTime: boolean = false;
	public startDate: NgbDate;
	public endDate: NgbDate;
	public minValidDate: NgbDate;
	public hoveredDate: NgbDate;
	public today: NgbDate;
	public setMaxRange: boolean;

	
	@Input() formGroup: AbstractControl;
	@Input() maxRange: number = 7;
	@Input() minRange: number = 2;
	@Input() daysBefore: number = 14;
	@Input() label: string;
	@Input() minDate: Date;
	@Input() maxDate: Date;
	@Input() showFooter: boolean = true;

	constructor(
		private calendar: NgbCalendar
	) { }


	public ngOnInit() {
		this.startDate = this.convertToNgbDate(this.formGroup.get('dateStart').value);
		this.endDate = this.convertToNgbDate(this.formGroup.get('dateEnd').value);
		this.minValidDate = this.convertJdateToNgbDate(this.minDate);
		this.today = this.calendar.getToday();
		this.setMaxRange = false;
	}

	private countFirstValidDate(startDate: NgbDate) {
		return this.calendar.getNext(startDate, "d", -this.daysBefore);
	}


	get firstValidDate() {
		const firstDay = this.countFirstValidDate(this.today);
		return firstDay.after(this.minValidDate) ? firstDay : this.minValidDate;
	}
	

	get lastValidDate() {

		return !!this.startDate && !!this.setMaxRange ? this.countLastAvailableDate(this.startDate) : !!this.maxDate ? this.convertJdateToNgbDate(this.maxDate) : this.calendar.getNext(this.today, "y", 10);
	}

	public isHovered(date: NgbDate) {
		return (
			!!this.startDate &&
			!this.endDate &&
			this.hoveredDate &&
			date.after(this.startDate) &&
			date.before(this.hoveredDate)
		);
	}

	public isInside(date: NgbDate) {
		return !!this.startDate && date.after(this.startDate) && date.before(this.endDate);
	}

	public isRange(date: NgbDate) {
		return (
			date.equals(this.startDate) ||
			date.equals(this.endDate) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	public countLastAvailableDate(startDate: NgbDate) {
		return this.calendar.getNext(startDate, "d", this.maxRange - 1);
	}

	public clearSelection() {
		this.startDate = this.endDate = null;
		this.formGroup.patchValue({ dateEnd: null, dateStart: null });
		console.log(this.startDate, this.endDate);
	}


	//Todo: use ngbDateAdapter instead like for time 
	public convertNgbDate(date: NgbDate, time: string): string {
		if (!!date) {
			const timeArray = time.split(":");
			return new Date(
				date?.year,
				date?.month - 1,
				date?.day,
				parseInt(timeArray[0], 10),
				parseInt(timeArray[1], 10)
			).toISOString();
		} else {
			return "";
		}
	}

	private convertToNgbDate(date: string): NgbDate {
		if (!date) return null;
		return this.convertJdateToNgbDate(new Date(date));
	}

	private convertJdateToNgbDate(jDate: Date): NgbDate {
		return new NgbDate(jDate.getFullYear(), jDate.getMonth() + 1, jDate.getDate());
	}


	public onDateSelection(date: NgbDate) {
		if (!this.startDate && !this.endDate) {
			this.startDate = date;
			this.setMaxRange = true;
			this.formGroup.patchValue({ dateStart: this.convertNgbDate(date, this.formGroup.get("timeStart").value) });
		} 
		else if (this.startDate && !this.endDate && (this.minRange === 1 || date.after(this.startDate))) {
			this.endDate = date.before(this.countLastAvailableDate(this.startDate)) ? date : this.countLastAvailableDate(this.startDate);
			this.setMaxRange = false;
			this.formGroup.patchValue({ dateEnd: this.convertNgbDate(this.endDate, this.formGroup.get("timeEnd").value) });
		}
		else {			
			this.endDate = null;
			this.startDate = date;
			this.setMaxRange = true;
			this.formGroup.patchValue({ dateEnd: this.convertNgbDate(null, null), dateStart: this.convertNgbDate(date, this.formGroup.get("timeStart").value) });
		}
	}
}