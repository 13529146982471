import { Component, Input, OnInit } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { LanguagesService } from '@frontend/shared/services';
import { translateToLink } from '@frontend/shared/helpers';

@Component({
  selector: 'frontend-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() menuItems: Array<{ text: string; href: string }>;
  @Input() logoSrc: string;
  constructor(
    private translateService: TranslateService,
    private languagesService: LanguagesService
  ) {}

  ngOnInit(): void {}

  goTo(href: string) {
    const translatedHref = translateToLink(
      href,
      this.languagesService,
      this.translateService
    );
    window.open(translatedHref, '_self');
  }
}
