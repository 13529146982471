<div
  class="wrapper"
  (click)="toggle()"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  *ngIf="countries$ | async as countries"
>
  <div class="flag-wrapper">
    <i
      *ngIf="isEuCountry(pickedCountry); else countryCode"
      class="flag flag-{{
        (pickedCountry ? pickedCountry.countryCode : 'gb') | lowercase
      }}"
    ></i>
    <ng-template #countryCode
      ><span>{{ pickedCountry.countryCode }}</span></ng-template
    >
  </div>
  <mat-icon>keyboard_arrow_down</mat-icon>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
  >
    <div>
      <div
        gdAuto="row"
        gdColumns="1fr 1fr"
        class="countries-list mat-elevation-z8"
        *ngIf="!otherSelection"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          *ngFor="let country of euCountries"
          (click)="pickCountry(country)"
          class="country-option"
        >
          <div class="country-flag">
            <div class="flag-wrapper">
              <i class="flag flag-{{ country.countryCode | lowercase }}"></i>
            </div>
          </div>
          <span>{{ country.countryName }}</span>
        </div>
        <button
          (click)="otherSelection = !otherSelection"
          type="button"
          class="btn btn-link"
        >
          Show more countries
        </button>
      </div>

      <div class="country-list-wrapper" *ngIf="otherSelection">
        <button
          (click)="otherSelection = !otherSelection"
          type="button"
          class="btn btn-link close-other-list-button"
        >
          &#10005;
        </button>
        <ul>
          <li
            *ngFor="let country of countries"
            [value]="country"
            (click)="pickCountry(country)"
          >
            {{ country.countryName }}
          </li>
        </ul>
      </div>
    </div>
  </ng-template>
</div>
