import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fadeAnimation } from '../../components/animations/fade.animation';
import { filter, takeWhile } from 'rxjs/internal/operators';

const titles = new Map([
  ['support', 'PP_CUSTOMER_SERVICE_TITLE'],
  ['what-we-do', 'PP_WHAT_WE_DO_TITLE'],
  ['compliance', 'PP_COMPLIANCE_REGULATIONS_TITLE'],
  ['about', 'PP_ABOUT_US_TITLE'],
  ['contacts', 'PP_SHOW_MORE_NUMBERS'],
]);

@Component({
  selector: 'pp-home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.component.html',
  animations: [fadeAnimation],
})
export class HomeComponent implements OnInit, OnDestroy {
  public title: string;
  private alive = true;

  constructor(private router: Router) {}

  public ngOnInit() {
    this.setTitle(this.router.url);
    this.router.events
      .pipe(
        takeWhile(() => this.alive),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((ev: NavigationEnd) => {
        this.setTitle(ev.urlAfterRedirects);
      });
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  private setTitle(url: string) {
    this.title = titles.get(url.split('/')[1]) || '';
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  public scrollTo(el) {
    if (el.getBoundingClientRect().top !== 0) {
      el.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }
}
