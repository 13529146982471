import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { protectedPages } from '../models/protectedPages';
import { AuthService } from '../services/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, takeWhile, finalize } from 'rxjs/operators';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  private loggedOut = false;
  constructor(private auth: AuthService, private route: Router) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        switch (err.status) {
          case 401: {
            if (
              this.auth.isAuthenticated() &&
              protectedPages.some((page) => this.route.url.includes(page)) &&
              !request.url.includes('token')
            ) {
              this.auth
                .logout()
                .pipe(
                  takeWhile(() => !this.loggedOut),
                  finalize(() => {
                    this.route.navigate(['/login'], {
                      state: {
                        prevUrl: this.route.url,
                        reason: 'Your session has expired. Please log in again',
                      },
                    });
                  })
                )
                .subscribe(() => {
                  this.loggedOut = true;
                });
              return throwError(err);
            }
          }
        }
        return throwError(err);
      })
    );
  }
}
