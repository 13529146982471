import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseLanguagePicker } from './base-language-picker.component';

@Component({
  selector: 'frontend-language-picker-text-dropdown',
  templateUrl: './language-picker-text-dropdown.component.html',
  styleUrls: ['./language-picker-text-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagePickerTextDropdownComponent extends BaseLanguagePicker {}
