import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from '../../../services';
import { distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'pp-mobile-home',
  styleUrls: ['./mobile-home.component.scss'],
  templateUrl: './mobile-home.component.html',
})
export class MobileHomeComponent implements OnInit, OnDestroy {
  public isLogged = false;
  private alive = true;

  constructor(
    private router: Router,
    private auth: AuthService,
    private layoutService: LayoutService
  ) {}

  public ngOnInit() {
    this.layoutService.redirectToHomeOnTablet(this.router).subscribe();
    this.auth.isLogged
      .pipe(
        distinctUntilChanged(),
        takeWhile(() => this.alive)
      )
      .subscribe((isLogged) => {
        this.isLogged = isLogged;
      });
  }

  public ngOnDestroy() {
    this.alive = false;
  }
}
