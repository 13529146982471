import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs/index";
import { AuthService } from "../../../services/auth.service";

@Component({
  selector: "pp-logout",
  template: ``,
})
export class LogoutComponent implements OnInit, OnDestroy {
  private subscriber: Subscription;

  constructor(private auth: AuthService, private router: Router) {}

  public ngOnInit() {
    this.subscriber = this.auth
      .logout()
      .subscribe(() => this.router.navigate(["/"]));
  }

  public ngOnDestroy() {
    this.subscriber.unsubscribe();
  }
}
