interface ValidateInfo {
  check: string;
  tariffCode?: string;
  version?: string;
}

export interface VehicleValidateMzInfo extends ValidateInfo {
  price: string;
  criteria: any;
  startDate: Date;
  endDate: Date;
}

export interface VehicleInfo extends ValidateInfo {
  status: string;
  vehicle?: string;
  category?: string;
  amount?: string;
  id?: string;
  registrationDate?: Date;
}

export interface ZoneAcceptanceCheck extends ValidateInfo {
  vehicle?: string;
  category?: string;
  status: string;
  registrationDate?: Date;
  implementedDate?: Date;
  amount?: number;
}
