<ng-container [ngSwitch]="type">
  <frontend-language-picker
    *ngSwitchCase="'text'"
    [currentLanguage]="currentLanguage$ | async"
    [languages]="languages"
    (langChanged)="onLangChanged($event)"
  ></frontend-language-picker>

  <frontend-language-picker-dropdown
    *ngSwitchCase="'dropdown'"
    [currentLanguage]="currentLanguage$ | async"
    [languages]="languages"
    (langChanged)="onLangChanged($event)"
  ></frontend-language-picker-dropdown>

  <frontend-language-picker-text-dropdown
    *ngSwitchCase="'text-dropdown'"
    [currentLanguage]="currentLanguage$ | async"
    [languages]="languages"
    (langChanged)="onLangChanged($event)"
  ></frontend-language-picker-text-dropdown>
</ng-container>
