import { Component, Input, forwardRef } from '@angular/core';
import { BaseThemedComponent } from '../base-themed.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface SelectItem {
  label: string;
  value: any;
}

@Component({
  selector: 'frontend-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent
  extends BaseThemedComponent
  implements ControlValueAccessor {
  @Input() options: SelectItem[];
  @Input() id: string;

  onChange: Function;
  onTouched: Function;

  selectedValue: any;
  isDisabled: boolean;

  writeValue(value: any): void {
    if (!value || value === this.selectedValue) return;
    this.selectedValue = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  change(value: any) {
    this.selectedValue = value;
    this.onChange(value);
    this.onTouched(value);
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
