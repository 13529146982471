import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';
import { ApplicationInsightsService } from '@frontend/shared/interfaces';
import { Injectable } from '@angular/core';

@Injectable()
export class MissingTranslationHelper implements MissingTranslationHandler {
  private missingKeys: string[] = [];

  constructor(private applicationInsightsService: ApplicationInsightsService) {}

  handle(params: MissingTranslationHandlerParams) {
    if (this.missingKeys.includes(params.key)) return;

    const message = `Missing translation key: ${params.key} ${params.translateService.currentLang}`;
    this.applicationInsightsService.trackError(new Error(message)); // todo create a LoggerService instead of injecting insights directly
    console.warn(message);
    this.missingKeys.push(params.key);
  }
}
