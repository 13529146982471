import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { AppStorageEnum } from '@frontend/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';

export const languages: Language[] = [
  { id: 'en', name: 'english', lang: 'en_GB', code: 'GB', text: 'English' },
  { id: 'bu', name: 'bulgarian', lang: 'bg_BG', code: 'BG', text: 'български' },
  { id: 'da', name: 'denmark', lang: 'da_DK', code: 'DK', text: 'Dansk' },
  { id: 'de', name: 'germany', lang: 'de_DE', code: 'DE', text: 'Deutsche' },
  { id: 'et', name: 'estonian', lang: 'et_EE', code: 'EE', text: 'Eesti' },
  { id: 'es', name: 'spanish', lang: 'es_ES', code: 'ES', text: 'Español' },
  { id: 'fr', name: 'france', lang: 'fr_FR', code: 'FR', text: 'Français' },
  { id: 'lv', name: 'latvian', lang: 'lv_LV', code: 'LV', text: 'Latviešu' },
  { id: 'lt',name: 'lithuanian', lang: 'lt_LT', code: 'LT', text: 'Lietuviešu' },
  { id: 'it', name: 'italian', lang: 'it_IT', code: 'IT', text: 'Italiano' },
  { id: 'nl', name: 'netherlands', lang: 'nl_NL', code: 'NL', text: 'Nederlands' },
  { id: 'no', name: 'norway', lang: 'no_NO', code: 'NO', text: 'Norsk' },
  { id: 'pl', name: 'polish', lang: 'pl_PL', code: 'PL', text: 'Polski' },
  { id: 'pt', name: 'portuguese', lang: 'pt_PT', code: 'PT', text: 'Português' },
  { id: 'ro', name: 'romanian', lang: 'ro_RO', code: 'RO', text: 'Românâ' },
  { id: 'fi', name: 'finland', lang: 'fi_FI', code: 'FI', text: 'Suomi' },
  { id: 'sv', name: 'swedish', lang: 'sv_SE', code: 'SE', text: 'Svenska' },

];

@Injectable({ providedIn: 'root' })
export class LanguagesService {
    private langSubject: BehaviorSubject<Language>;
    public activeLanguage: Language;
    public activeLanguage$: Observable<Language>;
    private defaultLang: any;
    private renderer: Renderer2;

  constructor(
        private translate: TranslateService,
        private location: Location,
        private router: Router,
        private rendererFactory: RendererFactory2,
        private environmentService: EnvironmentService,
        @Inject(DOCUMENT) private document
      ) {
        this.renderer = rendererFactory.createRenderer(null, null);
        const defaultLang = { id: 'da', name: 'denmark', lang: 'da_DK' };
        this.defaultLang = languages.find((l) => l.lang === this.environmentService.environment.defaultLang) ?? defaultLang;
   //     this.translate.setDefaultLang(defaultLang.lang);
        this.langSubject = new BehaviorSubject(this.activeLanguage);
        this.activeLanguage$ = this.langSubject.asObservable();
      }

  get getList(): Language[] {
    return languages;
  }

  public setCurrentLang(lang: string) {
    let urlToReplace;
    if (this.activeLanguage) {
      urlToReplace = '/' + this.activeLanguage.id + '/';
    }

    this.activeLanguage = languages.find((el) => lang === el.lang || lang === el.id) || this.defaultLang;

    sessionStorage.setItem(AppStorageEnum.storeLanguage,this.activeLanguage.lang);

    this.renderer.setAttribute(this.document.documentElement,'lang', this.activeLanguage.id);

    this.langSubject.next(this.activeLanguage);

    if (urlToReplace) {
      const replacer ='/' + languages.find((el) => lang === el.lang || lang === el.id).id + '/';
      this.location.replaceState(
        this.router.url.replace(urlToReplace, replacer)
      );
    }
    this.translate.setDefaultLang(this.defaultLang.lang);
    this.translate.use(this.activeLanguage.lang); // todo why is this not subscribed anywhere?
  }

  public getCurrentLang(): Language {
    return this.activeLanguage;
  }
}
export interface Language {
  id: string;
  name: string;
  lang: string;
  code: string;
  text: string;
}