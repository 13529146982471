import { Component } from '@angular/core';
import { fadeAnimation } from '../app/components/animations/fade.animation';

import { LoaderService, RouterService } from '../app/services';

@Component({
  selector: 'pp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
})
export class AppComponent {
  public isLoading = false;

  constructor(
    private loaderService: LoaderService,
    private routerService: RouterService
  ) {
    loaderService.loaderState.subscribe((isLoad) => {
      this.isLoading = isLoad;
    });
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }
}
