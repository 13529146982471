<ul class="languages-list">
  <li
    *ngFor="let language of languages"
    (click)="langClicked(language.language)"
    class="language-item"
    [class.selected]="isCurrentLang(language.language)"
  >
    {{ language.label | translate }}
  </li>
</ul>
