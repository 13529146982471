import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Multimedia } from '@frontend/shared/models';
import { ImagesData } from '../+state/rep.reducer';

export function minLengthArray(min: number) {
  // todo move to shared scope
  return (c: AbstractControl): { [key: string]: any } => {
    if (c.value.length >= min) return null;

    return { minLengthArray: { valid: false } };
  };
}

@Component({
  selector: 'rep-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss'],
})
export class Step3Component implements OnInit {
  form = new UntypedFormGroup({
    images: new UntypedFormControl([]),
  });
  get imagesControl() {
    return this.form.get('images');
  }

  private _formData: ImagesData;
  get formData(): ImagesData {
    return this._formData;
  }
  @Input() set formData(newFormData: ImagesData) {
    this._formData = newFormData;
    this.form.patchValue(newFormData, { emitEvent: false });
  }
  @Input() isLoading: boolean = false;

  @Output() formChanged = new EventEmitter<ImagesData>();

  @Output() proceed = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe(() => {
      this.formChanged.emit({ images: this.imagesControl.value });
    });
  }

  onImagesChanged(images: Multimedia[]) {
    let copiedArray = JSON.parse(JSON.stringify(images));
    this.imagesControl.setValue(copiedArray);
  }

  proceedClick(): void {
    this.proceed.emit();
  }
}
