import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable, ErrorHandler } from '@angular/core';
import { RemoteSettingsService } from './remote-settings.service';
import { ApplicationInsightsService as IApplicationInsightsService } from '@frontend/shared/interfaces';
import { EnvironmentService } from './environment.service';

@Injectable({ providedIn: 'root' })
export class ApplicationInsightsBasicService implements IApplicationInsightsService {
  
  private applicationInsights = new ApplicationInsights({
    config: {
      instrumentationKey: '608cb232-9e0a-4e5d-b9a0-3324f69ef1c1', //epass24
      namePrefix: 'appIns',
      sessionRenewalMs: 0,
      sessionExpirationMs: 0,
      isCookieUseDisabled: true
    },
  });

  constructor(private environmentService: EnvironmentService) {
    this.applicationInsights.loadAppInsights();
  }

  public trackError(error: Error) {
    this.applicationInsights.trackException({ error });
  }
//   public trackClaims(value) {
//     try {
//       this.appInsights.trackEvent({
//         name: 'claims body',
//         properties: value,
//       });
//     } catch (e) {
//       console.log(e);
//     }
//   }

//   public trackPaymentData(value) {
//     try {
//       this.appInsights.trackEvent({
//         name: 'payment data',
//         properties: value,
//       });
//     } catch (e) {
//       console.log(e);
//     }
//   }

//   public trackSession(value) {
//     try {
//       this.appInsights.trackEvent({
//         name: 'single pay sessionStorage',
//         properties: value,
//       });
//     } catch (e) {
//       console.log(e);
//     }
//   }

//   public trackSinglePayBody(value) {
//     try {
//       this.appInsights.trackEvent({
//         name: 'single pay body',
//         properties: value,
//       });
//     } catch (e) {
//       console.log(e);
//     }
//   }

//   public trackPage(value) {
//     try {
//       this.appInsights.trackEvent({
//         name: 'Page visit',
//         properties: value,
//       });
//     } catch (e) {
//       console.log(e);
//     }
//   }

//   public trackSinglePayResponse(value) {
//     try {
//       this.appInsights.trackEvent({
//         name: 'single pay response',
//         properties: value,
//       });
//     } catch (e) {
//       console.log(e);
//     }
//   }

//   public trackError(error: Error) {
//     try {
//       this.appInsights.trackException({ error });
//     } catch (e) {
//       console.log(e);
//     }
//   }

//   public trackStorage() {
//     const storedData = {};
//     for (let i = 0; i < sessionStorage.length; i++) {
//       const key = sessionStorage.key(i);
//       storedData[key] = sessionStorage.getItem(key);
//     }
//     try {
//       this.appInsights.trackEvent({
//         name: 'storage',
//         properties: storedData,
//       });
//     } catch (e) {
//       console.log(e);
//     }
//   }
}
