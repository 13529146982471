import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Multimedia, ServerTypes } from '@frontend/shared/models';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

export interface SendComplaintRequest {
  invoiceNo: string;
  vrm: string;
  clientId: string;
  cpr: string;
  email: string;
  address: string;
  postalCode: string;
  city: string;
  country: string; 
  comment: string;
  images: Multimedia[];
  name: string;
  lang: string;
}

@Injectable({ providedIn: 'root' })
export class ComplaintService {
  private httpHeaders: HttpHeaders;
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private authService: AuthService
  ) {
    this.httpHeaders = this.apiService.getHeaders(ServerTypes.uam, 0);
  }

  private get url(): string {
    return this.apiService.getCurentServer(ServerTypes.uam);
  }

  sendComplaint(request: SendComplaintRequest): Observable<any> {
    const authorizationHeaderValue =
      this.authService.getTokenType() + ` ${this.authService.getAuthToken()}`;
    const headersWithAuth = this.httpHeaders.append(
      'Authorization',
      authorizationHeaderValue
    );

    return this.http.post(`${this.url}/v1/complaint`, request, {
      headers: headersWithAuth,
    });
  }
}
