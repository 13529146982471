<div>
  <label class="uploader" [for]="fileInputId" ondragover="return false;"
    [style.outlineColor]="dragging ? activeColor : baseColor" (dragenter)="handleDragEnter()"
    (dragleave)="handleDragLeave()" (drop)="handleDrop($event)">
    <span class="placeholder">{{uploaderText | translate}}</span>
    <div class="file" *ngFor="let img of images">
      <img [src]="img.imgSource" />
      <button type="button" class="close" aria-label="Close" (click)="removeAttachment($event, img)">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="filename">{{ img.fileName }}</div>
    </div>
  </label>

  <div class="inputButtonDiv">
    <label [for]="fileInputId" class="btn btn-success">
      <i class="bi bi-cloud-upload-fill pr-2"></i>
      {{ uploadButtonText }}
    </label>
  </div>

  <input [id]="fileInputId" type="file" name="file" [multiple]="multi" onclick="this.value=null; return true;"
    (change)="handleInputChange($event)" />
</div>