<div ngbDropdown #dropdown [placement]="['bottom-right']" (openChange)="toggled($event)">
  <div class="selected-item btn btn-default item" ngbDropdownToggle>
    <span class="flag-wrap">
      <i [ngClass]="'flag flag-' + activeLanguage.id"></i>
    </span>
  </div>
  <div ngbDropdownMenu aria-labelledby="selectLanguage">
    <div class="row">
      <div class="col-6 item item-wrap"
           *ngFor="let item of languages"
           (click)="changeLanguage(item.lang)">
        <span class="flag-wrap">
          <i [ngClass]="'flag flag-' + item.id"></i>
        </span>
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</div>
