import {AbstractControl, ValidatorFn} from '@angular/forms';

export function notOnlySpacesValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    return  !control.value || control.value.replace(' ', '').length < 5 ? {
      nameTooShort: {
        value: control.value ? control.value.replace(/\s/g, '') : '',
      },
    } : null;
  };
}
