<div class="dropdown-wrapper" ngbDropdown>
  <div id="dropdownBasic1" ngbDropdownToggle class="language-button">
    <img
      class="current-language"
      src="assets/img/language-icon.png"
      width="20"
      height="20"
    />
    <span class="current-language-text" ngClass.lt-md="sm">{{
      currentLanguage.text
    }}</span>
    <img class="caret" src="assets/img/caret-down-fill.svg" />
  </div>

  <div ngbDropdownMenu ngClass.lt-md="sm" aria-labelledby="dropdownBasic1" class="language-picker">
    <div
      ngbDropdownItem
      *ngFor="let language of languages"
      (click)="langClicked(language.language)"
    >
      <span class="language-option" ngClass.lt-md="sm">{{
        language.language.text | translate
      }}</span>
    </div>
  </div>
</div>
