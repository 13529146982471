import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CreditCardFormModel } from '@frontend/shared/components';

export interface RegistrationDataModel {
  numberPlates: string[];
  creditCardInfo: CreditCardFormModel;
}

@Injectable({
  providedIn: 'root',
})
export class RegistrationDataService {
  private readonly initialState: RegistrationDataModel = {
    numberPlates: [],
    creditCardInfo: null,
  };
  private registrationDataBehaviorSubject: BehaviorSubject<
    RegistrationDataModel
  > = new BehaviorSubject<RegistrationDataModel>(this.initialState);

  registrationData$: Observable<
    RegistrationDataModel
  > = this.registrationDataBehaviorSubject.asObservable();

  updateRegistrationData(
    updateFunc: (storedValue: RegistrationDataModel) => RegistrationDataModel
  ) {
    this.registrationDataBehaviorSubject.next(
      updateFunc(this.registrationDataBehaviorSubject.value)
    );
  }
}
