<div ngbDropdown>
  <div id="dropdownBasic1" ngbDropdownToggle class="language-button">
    <img
      class="current-language"
      src="assets/img/flags/{{ currentLanguage.id }}.svg"
      width="27"
      height="27"
    />
    <img class="caret" src="assets/img/caret-down-fill.svg" />
  </div>

  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="language-picker">
    <div
      ngbDropdownItem
      *ngFor="let language of languages"
      (click)="langClicked(language.language)"
    >
      <img
        src="assets/img/flags/{{ language.language.id }}.svg"
        width="27"
        height="27"
        class="language-option"
      />
    </div>
  </div>
</div>
