import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  share,
  startWith,
} from 'rxjs/operators';

export interface MediaBreakpoint {
  name: string;
  width: number;
}

const SIMPLE_BREAKPOINTS = [
  {
    name: 'sm',
    width: 576,
  },
  {
    name: 'md',
    width: 768,
  },
];

@Injectable()
export class LayoutService {
  private changeWindowWidth$ = new ReplaySubject<number>(2);
  public winWidth$: Observable<number>;

  constructor() {
    this.winWidth$ = this.changeWindowWidth$.asObservable();
  }

  public changeWindowWidth(width: number): void {
    this.changeWindowWidth$.next(width);
  }

  public onMediaQueryChange(): Observable<MediaBreakpoint[]> {
    return this.changeWindowWidth$.pipe(
      startWith(1600),
      pairwise(),
      map(([prevWidth, width]: [number, number]) => {
        return [
          this.getBreakpointByWidth(prevWidth),
          this.getBreakpointByWidth(width),
        ];
      }),
      filter(([prevPoint, point]) => {
        return prevPoint.name !== point.name;
      }),
      distinctUntilChanged(null, (params) => params[0].name + params[1].name),
      share()
    );
  }

  private getBreakpointByWidth(width: number): MediaBreakpoint {
    const unknown = { name: 'unknown', width };
    // const breakpoints = BREAKPOINTS;
    const breakpoints = SIMPLE_BREAKPOINTS;

    return (
      breakpoints.find((point: MediaBreakpoint, index: number) => {
        const next = breakpoints[index + 1];
        return width >= point.width && (!next || width < next.width);
      }) || unknown
    );
  }
}
