import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { LanguagesService, LayoutService } from '../../services';

@Component({
  selector: 'pp-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
})
export class LanguagesComponent implements OnInit {
  public languages: any;
  public activeLanguage: any;
  @Output() public toggleLang = new EventEmitter();

  constructor(
    private langService: LanguagesService,
    private layoutService: LayoutService
  ) {
    this.languages = this.langService.getList();
  }

  public ngOnInit() {
    this.activeLanguage = this.langService.getCurrentLang();
    this.layoutService.onMediaQueryChange().subscribe(() => {
      this.activeLanguage = this.langService.getCurrentLang();
    });
  }

  public changeLanguage(lang: string) {
    this.langService.setCurrentLang(lang);
    this.activeLanguage = this.langService.getCurrentLang();
  }

  public toggled(isOpen: boolean) {
    this.toggleLang.next(isOpen);
  }
}
