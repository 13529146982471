import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BaseThemedComponent } from '../base-themed.component';
import { ThemeProvider, LanguagesService } from '@frontend/shared/services';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'frontend-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent extends BaseThemedComponent {
  @Input() steps: Array<{ title: string; path: string; stepIndex: number }>;
  @Input() routeKey: string;
  @Input() display: string = 'flex';

  private _completedStepsArr: number[];
  get completedStepsArr(): number[] {
    return this._completedStepsArr;
  }
  @Input() set completedStepsArr(completedStepsArr: number[]) {
    if (this._completedStepsArr === completedStepsArr) return;
    this._completedStepsArr = completedStepsArr;
    this.completedSteps = {};
    completedStepsArr.forEach((step) => (this.completedSteps[step] = true));
  }

  private _currentStep: number;
  get currentStep(): number {
    return this._currentStep;
  }
  @Input() set currentStep(currentStep: number) {
    if (this._currentStep === currentStep) return;
    this._currentStep = currentStep;
    this.selectedStepIndex = this.steps.findIndex(
      (_step) => _step.stepIndex === currentStep
    );
    this.navigate();
  }

  @Output() currentStepChange = new EventEmitter<number>();

  completedSteps: { [index: number]: boolean } = {};

  constructor(
    private router: Router,
    private languageService: LanguagesService,
    themeProvider: ThemeProvider
  ) {
    super(themeProvider);
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd && this.steps) {
        const currentStepFromRoute = this.steps.find((step) =>
          evt.url.includes(step.path)
        );
        if (currentStepFromRoute.stepIndex !== this.currentStep) {
          this.selectionChanged(currentStepFromRoute.stepIndex - 1);
        }
      }
    });
  }

  selectedStepIndex: number = 0;

  selectionChanged(index: number) {
    this.selectedStepIndex = index;
    this.currentStepChange.emit(this.steps[index].stepIndex);
  }

  private navigate(): void {
    const url = [
      this.languageService.getCurrentLang().id,
      ...this.routeKey.split('/'),
      `${this.steps[this.selectedStepIndex].path}`,
    ];
    this.router.navigate(url);
  }
}
