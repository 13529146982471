import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LayoutService } from '../../../services';

@Component({
  selector: 'pp-more-numbers',
  templateUrl: './more-numbers.component.html',
  styleUrls: ['./more-numbers.component.scss'],
})
export class MoreNumbersComponent implements OnInit, OnDestroy {
  public data;
  private alive = true;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private layoutService: LayoutService
  ) {
    this.data = [
      {
        country: 'Belgium',
        number: '+32 78 48 42 00',
        email: 'be@parkiapay.com',
      },
      {
        country: 'Bulgaria',
        number: '+359 2 421 97 36',
        email: 'no@parkiapay.com',
      },
      {
        country: 'Denmark',
        number: '+45 78 74 96 56',
        email: 'dk@parkiapay.com',
      },
      {
        country: 'Germany',
        number: '+49 211 97 63 11 88',
        email: 'de@parkiapay.com',
      },
      {
        country: 'Italy',
        number: '+41 21 349 25 25',
        email: 'it@parkiapay.com',
      },
      {
        country: 'Latvia',
        number: '+371 67 65 13 09',
        email: 'lv@parkiapay.com',
      },
      {
        country: 'Lithuania',
        number: '+370 5 214 12 00',
        email: 'lt@parkiapay.com',
      },
      {
        country: 'Norway',
        number: '+47 21 98 40 53',
        email: 'no@parkiapay.com',
      },
      {
        country: 'Sweden',
        number: '+46 10 22 02 230',
        email: 'sv@parkiapay.com',
      },
      {
        country: 'Switzerland',
        number: '+41 21 349 25 25',
        email: 'ch@parkiapay.com',
      },
      {
        country: 'Poland',
        number: '+48 223 07 25 00',
        email: 'pl@parkiapay.com',
      },
      {
        country: 'Portugal',
        number: '800 18 03 03',
        email: 'pt@parkiapay.com',
      },
      {
        country: 'Finland',
        number: '+358 753 25 14 10 (suomalainen)',
        number2: '+358 753 25 14 20 (svenska)',
        email: 'fi@parkiapay.com',
      },
    ];
  }

  public ngOnInit() {
    this.layoutService.redirectToHomeOnTablet(this.router).subscribe();
  }

  public ngOnDestroy() {
    this.alive = false;
  }
}
