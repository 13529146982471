import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerTypes, uamRequests } from '@frontend/shared/models';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private httpHeaders: HttpHeaders;
  constructor(private http: HttpClient, private apiService: ApiService) {
    this.httpHeaders = this.apiService.getHeaders(ServerTypes.uam, 0);
  }

  private get url(): string {
    return this.apiService.getCurentServer(ServerTypes.uam);
  }

  public validateNumberPlate(
    data: { npid: string; clientId: string },
    url: string = uamRequests.validateNumberPlate
  ): Observable<any> {
    return this.http.post(this.url + url, data, { headers: this.httpHeaders });
  }

  public validateEmail(account: {
    username: string;
    clientId: string;
  }): Observable<any> {
    return this.http.post(`${this.url}` + uamRequests.validateEmail, account, {
      headers: this.httpHeaders,
    });
  }

  public postAccount(body: object): Observable<any> {
    return this.http.post(`${this.url}/v1/users`, body, {
      headers: this.httpHeaders,
    });
  }
}
