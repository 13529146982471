import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestHeaders } from '../models/request-headers.enum';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServerTypes } from '../models/servers';
import { ApiService } from '../services/api.service';

@Injectable()
export class FailoverInterceptor implements HttpInterceptor {
  constructor(private apiService: ApiService) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          request.headers.get(RequestHeaders.server) != null
        ) {
          return this.failover(request, next, error);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private failover(
    request: HttpRequest<any>,
    next: HttpHandler,
    error: HttpErrorResponse
  ) {
    switch ((error as HttpErrorResponse).status) {
      case 0:
      case 404:
      case 500:
      case 503: {
        const type = request.headers.get(RequestHeaders.server);
        const retries: number =
          parseInt(request.headers.get(RequestHeaders.retries), 10) + 1;
        const baseUrl = this.apiService.parseUrl(
          request.url,
          ServerTypes[type]
        );
        const subAddress = request.url.replace(baseUrl, '');
        const headers = request.headers.set(
          RequestHeaders.retries,
          retries.toString()
        );

        this.apiService.updateApi2(ServerTypes[type]);

        if (retries < 4) {
          const newReq = request.clone({
            url:
              this.apiService.getCurentServer(ServerTypes[type]) + subAddress,
            headers,
          });

          return next.handle(newReq).pipe(
            catchError((err) => {
              if (error instanceof HttpErrorResponse) {
                return this.failover(newReq, next, err);
              } else {
                return throwError(error);
              }
            })
          );
        }
      }
    }
    return throwError(error);
  }
}
