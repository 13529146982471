import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { requests, ServerTypes } from '@frontend/shared/models';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable()
export class InvoiceService {
  private httpHeaders: HttpHeaders;
  constructor(private http: HttpClient, private apiService: ApiService) {
    this.httpHeaders = this.apiService.getHeaders(ServerTypes.claims, 0);
  }

  private get url(): string {
    return this.apiService.getCurentServer(ServerTypes.claims);
  }

  public getAdyenSession(body: any): Observable<any> {
    return this.http.post(`${this.url}/adyen/v1/session/`, body, {
      headers: this.httpHeaders,
    });
  }

  public getClaims(currency?: string): Observable<any> {
    const url = currency
      ? `${this.url}/v1/claims2?currency=${currency}`
      : `${this.url}/v1/claims2`;
    return this.http.get(url, { headers: this.httpHeaders }).pipe(
      catchError((error) => {
        return error;
      })
    );
  }
  public postPayment(body: object): Observable<any> {
    return this.http.post(`${this.url}/v2/wallet/payments`, body, {
      headers: this.httpHeaders,
    });
  }
}
