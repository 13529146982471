import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private fakeStorage: any = {};

  public getSessionStorageItem(item: string, fallback?: (...args: any[]) => any): any {
    try {
      return sessionStorage.getItem(item);
    } catch (e) {
      return fallback ? fallback() : this.getFakeStorageItem(item);
    }
  }

  public setSessionStorageItem(target: string, val, fallback?: (...args: any[]) => any): void {
    try {
      sessionStorage.setItem(target, val);
    } catch (e) {
      fallback ? fallback() : this.setFakeStorageItem(target, val);
    }
  }

  public removeSessionStorageItem(target: string, fallback?: (...args: any[]) => any) {
    try {
      sessionStorage.removeItem(target);
    } catch (e) {
      fallback ? fallback() : this.deleteFakeStorageItem(target);
    }
  }

  public getLocalStorageItem(item: string, fallback?: (...args: any[]) => any): any {
    try {
      return localStorage.getItem(item);
    } catch (e) {
      return fallback ? fallback() : this.getFakeStorageItem(item);
    }
  }

  public setLocalStorageItem(target: string, val, fallback?: (...args: any[]) => any): void {
    try {
      localStorage.setItem(target, val);
    } catch (e) {
      fallback ? fallback() : this.setFakeStorageItem(target, val);
    }
  }

  public removeLocalStorageItem(target: string, fallback?: (...args: any[]) => any) {
    try {
      localStorage.removeItem(target);
    } catch (e) {
      fallback ? fallback() : this.deleteFakeStorageItem(target);
    }
  }

  public setFakeStorageItem(key: string, value: any): void {
    this.fakeStorage[key] = value;
  }

  public getFakeStorageItem(key: string): string {
    return this.fakeStorage[key] || null;
  }

  public deleteFakeStorageItem(key: string): void {
    delete this.fakeStorage[key];
  }
}
