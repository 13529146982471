import { ElementRef, Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ErrorTexts } from '@frontend/shared/models';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ValidationService {
  constructor() {}

  public checkInput(tooltip: ElementRef) {
    tooltip.nativeElement.focus();
    setTimeout(() => {
      tooltip.nativeElement.blur();
    }, 0);
  }

  public validateInput(
    control: AbstractControl,
    tooltip: NgbTooltip,
    requiredFlag: boolean
  ) {
    const errorTexts = [];
    for (const error in control.errors) {
      if (
        ErrorTexts[error] &&
        ErrorTexts[error] === ErrorTexts.required &&
        !requiredFlag
      ) {
        continue;
      }
      errorTexts.push(ErrorTexts[error] ? ErrorTexts[error] : error);
    }
    if (tooltip) {
      errorTexts.length ? tooltip.open({ err: errorTexts }) : tooltip.close();
    }
  }
}
