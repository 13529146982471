import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RepPartialState, REP_FEATURE_KEY, State } from './rep.reducer';

export const getRepState = createFeatureSelector<State>(REP_FEATURE_KEY);

export const getImagesData = createSelector(
  getRepState,
  (state: State) => state.imagesData
);

export const getCompletedSteps = createSelector(
  getRepState,
  (state: State) => state.completedSteps
);

export const getCurrentStep = createSelector(
  getRepState,
  (state: State) => state.currentStep
);

export const getPaymentData = createSelector(
  getRepState,
  (state: State) => { 
    console.log("getPaymentData");
    return state.paymentData;
  }
);

export const getComplaintData = createSelector(
  getRepState,
  (state: State) => state.complaintData
);

export const step1Error = createSelector(
  getRepState,
  (state: State) => state.step1Error
);

export const isLoading = createSelector(
  getRepState,
  (state: State) => state.isLoading
);
