import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector
) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

    let lang = 'da_DK';
    if (/^.*\/en\//.test(window.location.href)) {
      lang = 'en_GB';
    }

    try {
      await translate.use(lang).toPromise();
    } catch (err) {}
  };
}

export function getLang(key: string): string {
  switch (key) {
    case 'da':
      return 'da_DK';
    case 'en':
    default:
      return 'en_GB';
  }
}
