import { Injectable, Optional } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum AppReadyState {
  TRANSLATION,
  REMOTE_SETTINGS,
  // add more if needed
}

export const APP_LOADING_SPINNER_ID = 'app-loading-spinner';

@Injectable({
  providedIn: 'root',
})
export class AppReadyService {
  private readyStatesSubject = new BehaviorSubject<AppReadyState[]>([]);
  private requiredStates: AppReadyState[] = [
    AppReadyState.TRANSLATION,
    AppReadyState.REMOTE_SETTINGS,
  ];
  readyStates$: Observable<
    AppReadyState[]
  > = this.readyStatesSubject.asObservable();

  constructor() {
    this.changeSpinnerVisibility(true);
  }

  setRequiredStates(...requiredStates: AppReadyState[]) {
    this.requiredStates = requiredStates;
  }

  reportReady(state: AppReadyState) {
    this.readyStatesSubject.next([...this.readyStatesSubject.value, state]);
    this.setLoadedIfAllReady();
  }

  private setLoadedIfAllReady() {
    const readyStates = this.readyStatesSubject.value;
    const allRequiredStatesReady = this.requiredStates.every((requiredState) =>
      readyStates.includes(requiredState)
    );

    if (allRequiredStatesReady) {
      this.changeSpinnerVisibility(false);
    }
  }

  private changeSpinnerVisibility(spinnerVisible: boolean) {
    const spinner = document.getElementById(APP_LOADING_SPINNER_ID);
    if (spinnerVisible) {
      spinner.classList.remove('hidden');
      spinner.classList.add('visible');
    } else {
      spinner.classList.remove('visible');
      spinner.classList.add('hidden');
    }
  }
}
