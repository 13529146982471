import {Component, Input} from '@angular/core';

@Component({
  selector: 'pp-banner-description',
  template: '<ng-content></ng-content>',
})
export class BannerDescriptionComponent {
}

@Component({
  selector: 'pp-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Input() public imgName: string;
}
