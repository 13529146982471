import { Component, OnInit, Input } from '@angular/core';
import {
  LanguagesService,
  Language,
  EnvironmentService,
} from '@frontend/shared/services';
import { Observable } from 'rxjs';

const defaultLanguages: string[] = ['en', 'da'];

@Component({
  selector: 'frontend-language-picker-container',
  templateUrl: './language-picker.container.html',
})
export class LanguagePickerContainer implements OnInit {
  @Input() type: 'text' | 'dropdown' | 'text-dropdown' = 'text';
  languages: { language: Language; label: string }[];
  currentLanguage$: Observable<Language>;
  constructor(
    private languagesService: LanguagesService,
    private environmentService: EnvironmentService
  ) {}

  ngOnInit() {
    this.currentLanguage$ = this.languagesService.activeLanguage$;
    const languageKeys: string[] =
      this.environmentService.environment.languageKeys ?? defaultLanguages;
    this.languages = this.languagesService.getList
      .filter((language) => languageKeys.includes(language.id))
      .map((language) => ({
        language,
        label: (language.id + '_LANG').toUpperCase(),
      }));
  }

  onLangChanged(newLanguage: Language) {
    this.languagesService.setCurrentLang(newLanguage.lang);
  }
}
