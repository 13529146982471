import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  takeWhile,
} from 'rxjs/internal/operators';
import { AboutUsComponent } from '../../pages/home/about-us/about-us.component';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'pp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [TranslatePipe],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public isMobileMenuShow = false;
  private alive = true;
  public isLogged = false;
  public user: any;
  private $isMobileMenuShow = new BehaviorSubject(this.isMobileMenuShow);

  @Output() public toggleScrolling = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    private auth: AuthService,
    private router: Router
  ) {}

  public ngOnInit() {
    this.auth
      .hasValidAuthToken()
      .pipe(takeWhile(() => this.alive))
      .subscribe();

    this.auth.isLogged
      .pipe(
        distinctUntilChanged(),
        takeWhile(() => this.alive)
      )
      .subscribe((isLogged) => {
        this.isLogged = isLogged;
        if (isLogged) {
          this.user = sessionStorage.getItem('numberPlate');
        }
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeWhile(() => this.alive)
      )
      .subscribe((ev) => {
        this.$isMobileMenuShow.next(false);
      });

    this.$isMobileMenuShow.subscribe((value) => {
      this.toggleScrolling.emit(value);
      this.isMobileMenuShow = value;
    });
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  public toggleMobileMenu(event) {
    event.preventDefault();
    this.$isMobileMenuShow.next(!this.isMobileMenuShow);
  }

  public closeMobileMenu(event) {
    event.preventDefault();
    this.$isMobileMenuShow.next(false);
  }

  public openAboutUs(event) {
    event.preventDefault();
    this.$isMobileMenuShow.next(false);
    this.modalService.open(AboutUsComponent, {
      centered: true,
      windowClass: 'modal-width-lg',
    });
  }

  public toggleLang(isOpen) {
    if (isOpen) {
      this.$isMobileMenuShow.next(false);
    }
    if (!this.isMobileMenuShow) {
      this.toggleScrolling.emit(isOpen);
    }
  }
}
