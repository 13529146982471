<frontend-card-page [title]="'REP_STEP3_HEADER' | translate">
  <div class="content" content>
    <p>
      {{ 'REP_STEP3_TEXT' | translate }}
    </p>
    <div class="uploader-wrapper">
      <frontend-file-uploader
        (imagesChanged)="onImagesChanged($event)"
        [preloadedFiles]="imagesControl.value"
        [uploadButtonText]="'REP_ATTACH_FILES' | translate"
      ></frontend-file-uploader>
    </div>
  </div>

  <div footer>
    <frontend-button
      class="next-button"
      [disabled]="!form.valid || isLoading"
      (click)="proceedClick()"
      type="button"
    >
      <ng-container *ngIf="!isLoading">
        {{ 'REP_SUBMIT' | translate }}
      </ng-container>
      <frontend-spinner *ngIf="isLoading"> </frontend-spinner>
    </frontend-button>
  </div>
</frontend-card-page>
