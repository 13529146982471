export const countriesEU = [
  {
    id: 'NO',
    name: 'Norway',
  },
  {
    id: 'BE',
    name: 'Belgium',
  },
  {
    id: 'BG',
    name: 'Bulgaria',
  },
  {
    id: 'DK',
    name: 'Denmark',
  },
  {
    id: 'EE',
    name: 'Estonia',
  },
  {
    id: 'FI',
    name: 'Finland',
  },
  {
    id: 'FR',
    name: 'France',
  },
  {
    id: 'DE',
    name: 'Germany',
  },
  {
    id: 'IT',
    name: 'Italy',
  },
  {
    id: 'LV',
    name: 'Latvia',
  },
  {
    id: 'LT',
    name: 'Lithuania',
  },
  {
    id: 'NL',
    name: 'Netherlands',
  },
  {
    id: 'PL',
    name: 'Poland',
  },
  {
    id: 'PT',
    name: 'Portugal',
  },
  {
    id: 'RO',
    name: 'Romania',
  },
  {
    id: 'SE',
    name: 'Sweden',
  },

];
