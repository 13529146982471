<pp-card>
  <pp-card-number>1</pp-card-number>
  <pp-card-title>{{ 'PP_COMPLIANCE_ABOUT_TITLE' | translate }}</pp-card-title>
  <pp-card-description>
    <p [innerHTML]="'PP_COMPLIANCE_ABOUT' | translate"></p>
  </pp-card-description>
</pp-card>

<pp-card>
  <pp-card-number>2</pp-card-number>
  <pp-card-title>{{ 'PP_COMPLIANCE_PERSONAL_DATA_TITLE' | translate }}</pp-card-title>
  <pp-card-description>
    <p [innerHTML]="'PP_COMPLIANCE_PERSONAL_DATA' | translate"></p>
  </pp-card-description>
</pp-card>

<pp-card>
  <pp-card-number>3</pp-card-number>
  <pp-card-title>{{ 'PP_COMPLIANCE_DATA_STORED_TITLE' | translate }}</pp-card-title>
  <pp-card-description>
    <p [innerHTML]="'PP_COMPLIANCE_DATA_STORED' | translate"></p>
  </pp-card-description>
</pp-card>

<pp-card>
  <pp-card-number>4</pp-card-number>
  <pp-card-title>{{ 'PP_COMPLIANCE_DATA_COLLECT_TITLE' | translate }}</pp-card-title>
  <pp-card-description>
    <p [innerHTML]="'PP_COMPLIANCE_DATA_COLLECT' | translate"></p>
  </pp-card-description>
</pp-card>


<pp-card>
  <pp-card-number>5</pp-card-number>
  <pp-card-title>{{ 'PP_COMPLIANCE_DATA_SHARED_TITLE' | translate }}</pp-card-title>
  <pp-card-description>
    <p [innerHTML]="'PP_COMPLIANCE_DATA_SHARED' | translate"></p>
  </pp-card-description>
</pp-card>
