<div class="modal-header d-none d-md-block">
  <h3 class="modal-title">All contacts
    <button type="button" class="close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
      <i class="pp-icon-close"></i>
    </button>
  </h3>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-4 col-12 col" *ngFor="let item of data">
      <div class="title"> {{ item.country }}</div>
      <div class="value">{{ item.number }}</div>
      <div class="value" *ngIf="item.number2">{{ item.number2 }}</div>
      <div class="value"><a href="mailto:{{item.email}}">{{ item.email }}</a></div>
    </div>
  </div>
</div>
