import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Country } from '@frontend/shared/models';

function isCountry(countryValue: Country | string): countryValue is Country {
  return !!countryValue && !!(countryValue as Country).countryCode
}

export const germanPlateNumberValidator = (
  numberPlateControlName: string,
  countryControlName: string
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const numberPlate = control.get(numberPlateControlName)?.value;
    const controlValue = control.get(countryControlName)?.value;

    const country = isCountry(controlValue) ? controlValue.countryCode : controlValue;

    const isGermanySelected =
      numberPlate && country && country.toUpperCase() === 'DE';
    if (!isGermanySelected) {
      return null;
    }

    const hasSeparator =
      numberPlate.substr(1, 3).includes(' ') ||
      numberPlate.substr(1, 3).includes('-');

    const result =
      hasSeparator === false
        ? {
            dePlateNumber: {
              value: 'separator expected',
            },
          }
        : null;
    return result;
  };
};
