import {Component, Input} from '@angular/core';

@Component({
  selector: 'pp-card-description',
  template: '<ng-content></ng-content>',
})
export class CardDescriptionComponent {
}

@Component({
  selector: 'pp-card-title',
  template: '<h4><ng-content></ng-content></h4>',
})
export class CardTitleComponent {
}

@Component({
  selector: 'pp-card-number',
  template: '<div class="pp-card-number"><ng-content></ng-content></div>',
})
export class CardNumberComponent {
}

@Component({
  selector: 'pp-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() public imgName: string;
}
