<ng-container *ngIf="theme === 'default'">
  <label [for]="for"
    ><ng-container *ngTemplateOutlet="content"></ng-container
  ></label>
</ng-container>

<ng-container *ngIf="theme === 'material'">
  <mat-label
    ><ng-container *ngTemplateOutlet="content"></ng-container
  ></mat-label>
</ng-container>

<ng-container *ngIf="theme === 'mz'">
  <label [for]="for"
    ><ng-container *ngTemplateOutlet="content"></ng-container
  ></label>
</ng-container>

<ng-template #content><ng-content></ng-content></ng-template>
