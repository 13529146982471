import { Directive, Input } from '@angular/core';
import { availableThemes, ThemeType } from '@frontend/shared/models';
import { ThemeProvider } from '@frontend/shared/services';

@Directive()
export class BaseThemedDirective {
  _overrideTheme: ThemeType;
  get overrideTheme(): ThemeType {
    return this._overrideTheme;
  }
  @Input() set overrideTheme(newTheme: ThemeType) {
    this.throwIfThemeDoesNotExist(newTheme);
    this._overrideTheme = newTheme;
  }

  private _theme: ThemeType = 'default';
  public get theme(): ThemeType {
    return this._theme;
  }
  public set theme(newTheme: ThemeType) {
    this.throwIfThemeDoesNotExist(newTheme);
    this._theme = newTheme;
  }

  constructor(private themeProvider: ThemeProvider) {}

  ngOnInit() {
    this.theme = this.overrideTheme
      ? this.overrideTheme
      : this.themeProvider.getCurrentTheme();
    this.afterNgOnInit();
  }

  private throwIfThemeDoesNotExist(theme: string) {
    if (
      availableThemes.some((availableTheme) => availableTheme === theme) ===
      false
    ) {
      throw new Error(`Theme '${theme}' is not supported!`);
    }
  }

  protected afterNgOnInit() {}
}
