import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Country } from '@frontend/shared/models';
import { InnerDropService } from '@frontend/shared/services';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'frontend-countries-picker',
  templateUrl: './countries-picker.component.html',
  styleUrls: ['./countries-picker.component.scss'],
})
export class CountriesPickerComponent implements OnInit {
  @Output() countryPicked = new EventEmitter<Country>();

  countries$: Observable<Country[]>;
  euCountries: Country[];
  pickedCountry: Country;
  isOpen = false;
  otherSelection = false;

  constructor(private innerDropService: InnerDropService) {
    this.countries$ = this.innerDropService.getCountries().pipe(
      tap((countries) => {
        let defaultCountry = countries.find(
          (country) => country.countryCode === 'GB'
        );
        this.pickCountry(defaultCountry);
      }),
      tap((countries) => {
        this.euCountries = this.innerDropService.getEUCountries(
          countries,
          false
        );
      })
    );
  }

  ngOnInit(): void {}

  toggle() {
    this.isOpen = !this.isOpen;
    this.otherSelection = false;
  }

  open() {
    this.isOpen = true;
    this.otherSelection = false;
  }

  close() {
    this.isOpen = false;
    this.otherSelection = false;
  }

  pickCountry(country: Country) {
    this.pickedCountry = country;
    this.countryPicked.emit(country);
    this.close();
  }

  isEuCountry(country: Country) {
    return (
      this.innerDropService.isEUmember(country) || country.countryCode === 'GB'
    );
  }
}
