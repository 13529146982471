<ng-container *ngIf="theme === 'default'">
  <textarea [id]="id" type="text" [(ngModel)]="value"></textarea>
</ng-container>

<ng-container *ngIf="theme === 'material'">
  <textarea [id]="id" type="text" [(ngModel)]="value"></textarea>
</ng-container>

<ng-container *ngIf="theme === 'mz'">
  <textarea
    class="form-control"
    [id]="id"
    type="text"
    [rows]="rows"
    [(ngModel)]="value"
  ></textarea>
</ng-container>
