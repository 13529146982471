<pp-card>
  <pp-card-number>1</pp-card-number>
  <pp-card-title>{{ 'PP_OUR_ROLE_TITLE' | translate }}</pp-card-title>
  <pp-card-description>
    <p [innerHTML]="'PP_OUR_ROLE' | translate"></p>
  </pp-card-description>
</pp-card>

<pp-card>
  <pp-card-number>2</pp-card-number>
  <pp-card-title>{{ 'PP_SECURE_AND_SAFE_TITLE' | translate }}</pp-card-title>
  <pp-card-description>
    <p [innerHTML]="'PP_SECURE_AND_SAFE' | translate"></p>
  </pp-card-description>
</pp-card>

<pp-card>
  <pp-card-number>3</pp-card-number>
  <pp-card-title>{{ 'PP_LOCAL_ASSISTANCE_TITLE' | translate }}</pp-card-title>
  <pp-card-description>
    <p [innerHTML]="'PP_LOCAL_ASSISTANCE' | translate"></p>
  </pp-card-description>
</pp-card>

<pp-card>
  <pp-card-number>4</pp-card-number>
  <pp-card-title>{{ 'PP_EQUAL_TREATMENT_TITLE' | translate }}</pp-card-title>
  <pp-card-description>
    <p [innerHTML]="'PP_EQUAL_TREATMENT' | translate"></p>
  </pp-card-description>
</pp-card>
