import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {
  private loaderSubject = new BehaviorSubject(false);
  public loaderState = this.loaderSubject.asObservable();

  constructor() {}

  public show() {
    this.loaderSubject.next(true);
  }

  public hide() {
    this.loaderSubject.next(false);
  }
}
