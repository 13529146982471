import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'frontend-card-page',
  templateUrl: './card-page.component.html',
  styleUrls: ['./card-page.component.scss'],
})
export class CardPageComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() contentLayout: 'stretch' | 'center' = 'center';

  constructor() {}

  ngOnInit(): void {}
}
