import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CoreStorageEnum,
  defaultServerConfig,
  servers,
  ServerTypes,
} from '@frontend/shared/models';
import { EnvironmentService } from './environment.service';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class ApiService {
  private environment;
  constructor(
    private storage: StorageService,
    private environmentService: EnvironmentService
  ) {
    this.environment = this.environmentService.environment;
  }

  private currentServer = defaultServerConfig;

  public updateApi2(serverType: ServerTypes): boolean {
    this.setNextIndex(serverType);
    return true;
  }

  public getCurentServer(serverType: ServerTypes) {
    const index = this.getCurrentIndex(serverType);
    return this.getServerByIndex(serverType, index);
  }

  public getHeaders(serverType: ServerTypes, retries: number) {
    if (this.storage.getSessionStorageItem(CoreStorageEnum.testId)) {
      return new HttpHeaders({
        'X-testId': this.storage.getSessionStorageItem(CoreStorageEnum.testId),
        'X-resilience-server': serverType,
        'X-resilience-retries': retries.toString(),
      });
    }
    if (this.environment.testId != null) {
      return new HttpHeaders({
        'X-testId': this.environment.testId.toString(),
        'X-resilience-server': serverType,
        'X-resilience-retries': retries.toString(),
      });
    }
    return new HttpHeaders({
      'X-resilience-server': serverType,
      'X-resilience-retries': retries.toString(),
    });
  }

  public parseUrl(url: string, type: ServerTypes) {
    let server;
    servers.forEach((q) => {
      if (type === q.type) {
        q.url.forEach((z) => {
          if (url.startsWith(z)) {
            server = z;
          }
        });
      }
    });
    return server;
  }

  private getCurrentIndex(serverType: ServerTypes) {
    let index: number = 0;
    this.currentServer.forEach((q) => {
      if (q.type === serverType) {
        index = q.index;
      }
    });
    return index;
  }

  private setNextIndex(serverType: ServerTypes) {
    let index: number = this.getCurrentIndex(serverType) + 1;
    if (this.getServerByIndex(serverType, index) === null) {
      index = 0;
    }
    this.currentServer.forEach((q) => {
      if (q.type === serverType) {
        q.index = index;
      }
    });
  }

  private getServerByIndex(type: ServerTypes, index: number) {
    let server = null;
    servers.forEach((q) => {
      if (type === q.type && q.url.length - 1 >= index) {
        server = q.url[index];
      }
    });
    return server;
  }
}
