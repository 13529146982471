import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'rep-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.scss'],
})
export class Step4Component implements OnInit {
  @Output() print = new EventEmitter();
  @Output() finish = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  printClick() {
    this.print.emit();
  }
  finishClick() {
    this.finish.emit();
  }
}
