const test = 'test_W6AABNCWN5HQ3IC4ECS2GHP3YIGZUXUM';

export const environment = {
  production: false,
  langUrl: 'https://d18oow8b9b9oyq.cloudfront.net/parkiapay/languages',
  appUrl: 'https://d18oow8b9b9oyq.cloudfront.net',
  authUrl: ['https://api1.parkiapay.com/v1'],
  apiUrl: 'https://api1.parkiapay.com',
  accountApiUrl: 'https://api1.parkiapay.com',
  version: 'v1',
  appVersion: '2.51.07',
  clientId2: '1201', // static for parkia-pay
  clientId: '12008', // clientId for SharedPaymentModule
  testId: null,
  adyenClientKey: test,
  payment: 'adyenchk', // 'adyencse' | 'adyenchk',
  theme: 'mz',
  paymentSuccess: 'pay/step4',
};
