<ng-container *ngIf="theme === 'default'">
  <div><ng-container *ngTemplateOutlet="content"></ng-container></div>
</ng-container>

<ng-container *ngIf="theme === 'material'">
  <mat-form-field>
    <input matInput hidden />
    <!-- Material form field won't work with projected content -->
    <ng-container *ngTemplateOutlet="content"></ng-container
  ></mat-form-field>
</ng-container>

<ng-container *ngIf="theme === 'mz'">
  <div class="form-group">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-container>

<ng-template #content><ng-content></ng-content></ng-template>
