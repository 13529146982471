<ng-container *ngIf="theme === 'default'">
  <ng-container *ngFor="let radio of radios">
    <input
      [id]="radio.id"
      type="radio"
      [value]="radio.value"
      [ngModel]="value"
      (ngModelChange)="change($event)"
    />
    <label [for]="radio.id">{{ radio.label }}</label>
  </ng-container>
</ng-container>

<ng-container *ngIf="theme === 'material'">
  <mat-radio-group [ngModel]="value" (ngModelChange)="change($event)">
    <mat-radio-button
      *ngFor="let radio of radios"
      [id]="radio.id"
      [value]="radio.value"
      >{{ radio.label }}</mat-radio-button
    >
  </mat-radio-group>
</ng-container>

<ng-container *ngIf="theme === 'mz'">
  <div style="display: flex; flex-direction: row">
    <div
      *ngFor="let radio of radios"
      style="display: flex; align-items: center; padding-right: 1rem"
    >
      <input
        [id]="radio.id"
        type="radio"
        [value]="radio.value"
        [ngModel]="value"
        (ngModelChange)="change($event)"
        style="width: 1rem; height: 1rem; margin-right: 10px"
      />
      <label [for]="radio.id" style="margin: 0">{{ radio.label }}</label>
    </div>
  </div>
</ng-container>
