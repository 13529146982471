<div class="row">
  <div class="col-md-6 login-wrap">
    <button class="back close" (click)="closeLogin()">
      <i class="pp-icon-arrow-back"></i>
    </button>
    <div>
      <h3 class="title">{{ 'PP_LoginHeader' | translate }}</h3>
      <div class="description"> {{ 'PP_LoginText' | translate }}</div>

      <form [formGroup]="loginForm" (submit)="submitLogin()" novalidate
            [class.incorrect]="loginForm.hasError('incorrect')">
        <div class="form-group"
             [class.is-invalid]="loginForm.get('plateNumber').invalid && isFormSubmitted"
             [class.is-valid]="loginForm.get('plateNumber').valid && isFormSubmitted">
          <label>{{ 'PP_LoginPLN' | translate }}</label>
          <input formControlName="plateNumber" type="text" class="form-control"
                 placeholder="AB 44 887">
        </div>
        <div class="form-group"
             [class.is-invalid]="loginForm.get('refNumber').invalid && isFormSubmitted"
             [class.is-valid]="loginForm.get('refNumber').valid && isFormSubmitted">
          <label>{{ 'PP_LoginReference' | translate }}</label>
          <input formControlName="refNumber"
                 type="text"
                 ppOnlyNumber
                 class="form-control hide-arrow" placeholder="_ _ _ _ _ _ _ _ _">
        </div>

        <div class="small">{{ 'PP_LoginAllfields' | translate }}</div>

        <div *ngIf="isError" class="mb-3 text-danger"> {{ 'PP_LOGIN_ERROR' | translate }}</div>
        <div class="row">
          <div class="col">
            <button class="btn btn-success" type="submit" [disabled]="isLoading">
              <span *ngIf="!isLoading">{{ 'PP_LoginHeader' | translate }}</span>
              <i *ngIf="isLoading" class="fas fa-sync fa-spin fa-lg" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="col-md-6 d-none d-md-block">
    <div class="image-wrap">
        <div class="image"></div>
    </div>
  </div>
</div>
