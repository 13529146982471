import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { VehicleInfo } from '@frontend/shared/interfaces';
import { ServerTypes } from '@frontend/shared/models';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from './api.service';
import { LanguagesService } from './languages.service';

@Injectable()
export class LookupService {
  private readonly dateReg = /^\d{4}-\d{2}-\d{2}$/;
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private router: Router,
    private languageService: LanguagesService
  ) {}

  private get url(): string {
    return this.apiService.getCurentServer(ServerTypes.vclass);
  }

  public getVehicleInfo(vrmid: string, date: string = null): Observable<VehicleInfo> {
    let url = `${this.url}/v1/vehicles/lez/mz/${vrmid}`;
    if(!!date && date.match(this.dateReg)) url = `${url}?date=${date}`;
    return this.http
      .get<VehicleInfo>(url)
      .pipe(catchError(this.handleError()));
  }

  private handleError() {
    return (error: any): Observable<any> => {
      console.error(error);
      this.router.navigate([
        this.languageService.getCurrentLang().id,
        'lookup',
        'step1',
      ]);
      return of(error);
    };
  }
}
