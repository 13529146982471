import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { HttpTranslateFactory } from '../helpers';

import {
  BannerComponent,
  BannerDescriptionComponent,
  CardComponent,
  CardDescriptionComponent,
  CardNumberComponent,
  CardTitleComponent,
  FooterComponent,
  HeaderComponent,
  LanguagesComponent,
  LayoutComponent,
  SpinnerComponent,
} from './index';

import { AuthModule } from '../pages/auth/auth.module';

const COMPONENTS = [
  LanguagesComponent,
  CardTitleComponent,
  CardDescriptionComponent,
  CardNumberComponent,
  CardComponent,
  BannerComponent,
  BannerDescriptionComponent,
  FooterComponent,
  HeaderComponent,
  SpinnerComponent,
  LayoutComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    NgbModule,
    CommonModule,
    RouterModule,
    TranslateModule.forChild({
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpTranslateFactory,
      },
    }),
    AuthModule,
  ],
})
export class ComponentsModule {}
