import {
  Component,
  EventEmitter,
  Input,
  Output,
  HostBinding,
} from '@angular/core';
import { BaseThemedComponent } from '../base-themed.component';

@Component({
  selector: 'frontend-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent extends BaseThemedComponent {
  @Input() disabled: boolean = false;
  @Input() type: string = 'button';
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('class.w-100') get fullWidth() {
    return this.theme === 'mz';
  }

  buttonClicked(): void {
    this.onClick.emit();
  }
}
