import { Injectable } from '@angular/core';
import { AppStorageEnum } from '@frontend/shared/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PayService {
  private createAccountFormSubject: BehaviorSubject<
    object
  > = new BehaviorSubject<object>({});
  public createAccountData$: Observable<object>;

  constructor() {
    this.createAccountData$ = this.createAccountFormSubject.asObservable();
  }

  public updateAccountData(data: object) {
    this.createAccountFormSubject.next(
      data
        ? sessionStorage.getItem(AppStorageEnum.temp)
          ? Object.assign(
              JSON.parse(sessionStorage.getItem(AppStorageEnum.temp)),
              data
            )
          : data
        : {}
    );
    sessionStorage.setItem(
      AppStorageEnum.temp,
      JSON.stringify(this.createAccountFormSubject.value)
    );
  }
}
