<div class="wrapper" style="display: flex;flex-direction: column;flex: 1 0 auto;">
  <div 
    class="header"
    ngClass.lt-md="header wide"
    gdArea
    gdColumns.lt-md="1fr"
 
    gdRows="minmax(0, 1fr)"
  >
    <div gdColumn.lt-md="1" gdColumn.gt-sm="2">
      <ng-content select="frontend-header"></ng-content>
    </div>
  </div>
  <main
    gdArea
    gdColumns.lt-md="1fr"
  
    gdRows.lt-md="1fr"
    gdRows.gt-sm="5fr 3fr"
  >
    <div class="content" gdColumn.lt-md="1" gdColumn.gt-sm="2" gdRow="1">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
