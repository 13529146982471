import { Injectable } from '@angular/core';
import { languages, Language } from './languages.service';
import { Country } from '@frontend/shared/models';

function getWindow() {
  return window;
}

function getUserLanguage() {
  const language: string =
    getWindow().navigator.language ||
    (getWindow().navigator as any).userLanguage;

  return language.substr(0, 2);
}

@Injectable({
  providedIn: 'root',
})
export class UserBrowserInfoService {
  getUserLanguage(): Language | null {
    const userLanguage = getUserLanguage().toLowerCase();

    if (!userLanguage) return null;

    const languageFoundById = languages.find(
      (language) => language.id.toLowerCase() === userLanguage
    );
    if (languageFoundById) return languageFoundById;

    return null;
  }

  getUserCountryCode(): string | null {
    const userLanguage = getUserLanguage().toLowerCase();

    return this.getUserLanguage()?.code ?? userLanguage;
  }

  findUserCountry(countries: Country[]): Country | null {
    const userCountryCode = this.getUserCountryCode();

    if (userCountryCode)
      return countries.find(
        (country) =>
          country.countryCode.toLowerCase() === userCountryCode.toLowerCase()
      );

    return null;
  }
}
