import { Directive, Input, Output, EventEmitter } from '@angular/core';
import { Language } from '@frontend/shared/services';

@Directive()
export abstract class BaseLanguagePicker {
  @Input() languages: { language: Language; label: string }[];
  @Input() currentLanguage: Language;
  @Output() langChanged = new EventEmitter<Language>();

  constructor() {}

  langClicked(language: Language) {
    if (!this.isCurrentLang(language)) this.langChanged.emit(language);
  }

  isCurrentLang(language: Language): boolean {
    return language?.id === this.currentLanguage?.id;
  }
}
