<div class="payment">
  <form [formGroup]="cardForm" novalidate>
    <div class="form-inputs">
      <div class="row align-items-end">
        <div
          class="form-group col-md-7 col-xxl-7"
          [class.is-invalid]="
            cardForm.get('creditCardNumber').invalid && isFormSubmitted
          "
          [class.is-valid]="
            cardForm.get('creditCardNumber').valid &&
            (isFormSubmitted || cardForm.get('creditCardNumber').dirty)
          "
        >
          <div class="credit-cards mb-4 d-none d-sm-block"></div>

          <label>{{ 'PAY_CARDNUMBER' | translate }}</label>
          <input
            formControlName="creditCardNumber"
            #cardNumber
            card-number
            type="tel"
            class="form-control hide-arrow"
            placeholder=""
            (ngModelChange)="addSpacesToCardNumber($event)"
            autocomplete="on"
            [maxlength]="19"
            id="card-number-input"
          />
        </div>
      </div>
      <div class="row">
        <div
          class="form-group col-md-7 col-xxl-7 col-12"
          [class.is-invalid]="cardForm.get('name').invalid && isFormSubmitted"
          [class.is-valid]="
            cardForm.get('name').valid &&
            (isFormSubmitted || cardForm.get('name').dirty)
          "
        >
          <label>{{ 'PAY_CARD_HOLDER' | translate }}</label>
          <input
            formControlName="name"
            type="text"
            class="form-control"
            autocomplete="off"
            id="card-holder-input"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 second-block mt-md-0 mb-md-0">
          <div class="date">
            <div
              class="form-group"
              [class.is-invalid]="
                cardForm.get('expdate').invalid && isFormSubmitted
              "
              [class.is-valid]="
                cardForm.get('expdate').valid &&
                (isFormSubmitted || cardForm.get('expdate').dirty)
              "
            >
              <label>{{ 'PAY_EXP_DATE' | translate }}</label>
              <input
                formControlName="expdate"
                #expDate
                maxlength="5"
                type="tel"
                class="form-control hide-arrow"
                inputmode="numeric"
                placeholder="MM/YY"
                ppOnlyNumber
                autocomplete="cc-exp"
              />
            </div>
          </div>

          <div class="cvc">
            <div
              class="form-group"
              [class.is-invalid]="
                cardForm.get('cvc').invalid && isFormSubmitted
              "
              [class.is-valid]="
                cardForm.get('cvc').valid &&
                (isFormSubmitted || cardForm.get('cvc').dirty)
              "
            >
              <label for="cvc">{{ 'PAY_CVC' | translate }}</label>
              <input
                formControlName="cvc"
                #cvc
                card-cvc
                class="form-control hide-arrow"
                inputmode="numeric"
                ppOnlyNumber
                id="cvc"
                pattern="[0-9]*"
                type="tel"
                autocomplete="off"
                [maxlength]="3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="error" class="mt-3">
      <span class="text-danger">
        {{ error | translate }}
      </span>
    </div>
  </form>
</div>
<div id="threedsContainer"></div>
<form method="POST" #form3d id="3dform">
  <input type="hidden" name="PaReq" #pa />
  <input type="hidden" name="MD" #md />
  <input type="hidden" name="TermUrl" #termUrl />
</form>
