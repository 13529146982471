import { Injectable } from '@angular/core';
import { ThemeType } from '@frontend/shared/models';
import { EnvironmentService } from './environment.service';

@Injectable({ providedIn: 'root' })
export class ThemeProvider {
  constructor(private environmentService: EnvironmentService) {}

  getCurrentTheme(): ThemeType {
    return this.environmentService.environment.theme ?? 'default';
  }
}
