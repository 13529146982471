export interface InvoiceModel {
  incidentDate?: Date; // fixme: remove imagine field
  issuer?: string;
  incidentLocation?: string;
  statusLog?: {
    0?: Date;
    1?: Date;
    2?: Date;
    3?: Date;
  };
  appealDate?: Date;
  decisionDate?: Date;
  isDisabledStatus?: boolean;

  checked?: boolean;
  evidences?: Array<{ source?: string, name: string }>;

  referenceNumber: number;
  vehicle: string;
  items: Array<{
    itemType: number;
    numberPlate: string;
    amount: number;
    isPaid: boolean;
    paymentInfo: string;
  }>;
  images: string[];
  periodFrom: Date;
  periodTo: Date;
  outstandingAmount: number;
  pendingAmount: number;
  amount: number;
  currency: string;
  claimDate: Date;
  dueDate: Date;
  isPaid: boolean;
  type: string;
  status: string;
  paymentInfo: string;

}
