<frontend-card-page [title]="'REP_STEP2_HEADER' | translate">
  <div content fxLayout="column" class="content">
    <p>
      {{ 'REP_STEP2_TEXT' | translate }}
    </p>
    <div class="form-wrapper">
      <frontend-form [formGroup]="form" fxLayout="column" fxFlex="1 1 auto">
        <frontend-form-group>
          <frontend-label for="email">{{
            'REP_EMAIL' | translate
          }}</frontend-label>
          <frontend-text-input
            [characters]="20"
            type="text"
            id="email"
            formControlName="email"
          >
          </frontend-text-input>
        </frontend-form-group>
        <frontend-form-group>
          <frontend-label for="complaint">{{
            'REP_COMMENT' | translate
          }}</frontend-label>
          <frontend-text-area-input id="complaint" formControlName="complaint">
          </frontend-text-area-input>
        </frontend-form-group>
        <frontend-button
          class="next-button"
          [disabled]="!form.valid"
          (click)="submitForm()"
          type="button"
        >
          {{ 'REP_NEXT' | translate }}
        </frontend-button>
      </frontend-form>
    </div>
  </div>
</frontend-card-page>
