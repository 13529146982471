import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from '../services/api.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { CoreStorageEnum } from '../models/core-storage.enum';
import { RequestHeaders } from '../models/request-headers.enum';
import { ServerTypes } from '../models/servers';
import { appAuthCleanUpItems } from '../models/storage-logout-remove-items';

@Injectable()
export class AuthService {
  private $isLogged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this.isAuthenticated()
  );
  public isLogged: Observable<boolean>;

  constructor(private httpClient: HttpClient, private apiService: ApiService) {
    this.isLogged = this.$isLogged.asObservable();
  }

  public get url(): string {
    return this.apiService.getCurentServer(ServerTypes.auth);
  }

  public isAuthenticated(): boolean {
    return !!this.getAuthToken();
  }

  public getAuthToken(): string {
    return sessionStorage.getItem(CoreStorageEnum.tokenName);
  }

  public setAuthToken(token: string, type: string, clientId: string) {
    sessionStorage.setItem(CoreStorageEnum.tokenName, token);
    sessionStorage.setItem(CoreStorageEnum.tokenType, type);
    sessionStorage.setItem(CoreStorageEnum.clientId, clientId);
  }

  public getTokenType(): string {
    return sessionStorage.getItem(CoreStorageEnum.tokenType);
  }

  public login(
    username: string,
    password: string,
    clientId: string
  ): Observable<any> {
    const body = {
      version: environment.appVersion,
      clientId,
      username,
      password,
    };

    return this.httpClient
      .post(`${this.url}/v1/auth/token`, body, {
        headers: this.apiService.getHeaders(ServerTypes.auth, 0),
      })
      .pipe(
        tap((res) => {
          sessionStorage.setItem(CoreStorageEnum.username, username);
          this.setAuthToken(
            res[CoreStorageEnum.tokenName],
            res[CoreStorageEnum.tokenType],
            clientId
          );
          this.$isLogged.next(true);
          this.lastCheck = new Date();
        })
      );
  }

  public logout(): Observable<any> {
    const headers = this.apiService
      .getHeaders(ServerTypes.auth, 0)
      .set(
        RequestHeaders.auth,
        this.getTokenType() + ` ${this.getAuthToken()}`
      );

    if (this.isAuthenticated()) {
      return this.httpClient
        .delete(`${this.url}/v1/auth/token`, { headers })
        .pipe(
          finalize(() => {
            this.clearUserData();
          })
        );
    } else {
      this.$isLogged.next(false);
      return of(false);
    }
  }

  public getClientId(): string {
    return sessionStorage.getItem(CoreStorageEnum.clientId);
  }
  public tempLogged = false;
  public lastCheck: Date = new Date();
  public async isAuthenticatedAsync() {
    if (!this.isAuthenticated()) {
      return false;
    }

    // console.log(this.getDateDiff(this.lastCheck) + 'ms since last check.');
    if (this.getDateDiff(this.lastCheck) < 60000) {
      return true;
    } // check token once per minute

    // console.log('do token check against');
    this.lastCheck = new Date();
    const headers = this.apiService
      .getHeaders(ServerTypes.auth, 0)
      .set(
        RequestHeaders.auth,
        this.getTokenType() + ` ${this.getAuthToken()}`
      );
    await this.httpClient
      .get(`${this.url}/v1/auth/token`, { headers })
      .toPromise()
      .then(
        (res) => {
          this.tempLogged = true;
        },
        (err) => {
          this.tempLogged = false;
        }
      );
    return this.tempLogged;
  }
  public getDateDiff(startDate) {
    return new Date().getTime() - startDate.getTime();
  }

  public hasValidAuthToken(): Observable<any> {
    if (this.isAuthenticated()) {
      const headers = this.apiService
        .getHeaders(ServerTypes.auth, 0)
        .set(
          RequestHeaders.auth,
          this.getTokenType() + ` ${this.getAuthToken()}`
        );
      return this.httpClient.get(`${this.url}/v1/auth/token`, { headers }).pipe(
        tap(() => {
          this.$isLogged.next(true);
        }),
        catchError((err) => {
          this.clearUserData();
          return of(err);
        })
      );
    }
    return of(true);
  }

  public clearUserData() {
    sessionStorage.removeItem(CoreStorageEnum.username);
    sessionStorage.removeItem(CoreStorageEnum.tokenName);
    sessionStorage.removeItem(CoreStorageEnum.tokenType);
    sessionStorage.removeItem(CoreStorageEnum.clientId);
    if (appAuthCleanUpItems.length) {
      appAuthCleanUpItems.forEach((item) => {
        sessionStorage.removeItem(String(item));
      });
    }
    this.$isLogged.next(false);
  }
}
