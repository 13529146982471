import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { BaseFormThemedComponent } from '../base-form-themed.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'frontend-text-area-input',
  templateUrl: './text-area-input.component.html',
  styleUrls: ['./text-area-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaInputComponent),
      multi: true,
    },
  ],
})
export class TextAreaInputComponent extends BaseFormThemedComponent {
  @Input() id: string;
  @Input() rows: number = 10;
}
