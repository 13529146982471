import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LayoutService } from '../../../services';

@Component({
  selector: 'pp-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit, OnDestroy {
  private alive = true;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private layoutService: LayoutService
  ) {}

  public ngOnInit() {
    this.layoutService.redirectToHomeOnTablet(this.router).subscribe();
  }

  public ngOnDestroy() {
    this.alive = false;
  }
}
