import { LanguagesService } from '@frontend/shared/services';
import { TranslateService } from '@ngx-translate/core';

export const translateToLink = (
  translationKey: string,
  languagesService: LanguagesService,
  translateService: TranslateService
): string => {

  const translatedKey = translateService.instant(translationKey) as string;

  console.log(`${languagesService.getCurrentLang().id}/${translatedKey}`);
  
  if (translatedKey.startsWith('http')) {
    return translatedKey;
  } else {
    return `${languagesService.getCurrentLang().id}/${translatedKey}`;
  }
};
