import { CoreStorageEnum } from "./core-storage.enum";

export enum AppStorageEnum {
  storeLanguage = "user_language",
  temp = "temp1",
  complete = "complete",
  successUrl = "successUrl",
  country = "country",
  pending = "pending",
  totalPaid = "totalPaid",
  paymentTime = "paymentTime",
  accountNumber = "accountNumber",
  paymentData = "paymentData",
  puid = "puid",
  reference = "reference",
  numberPlate = "numberPlate",
}

export type StorageEnum = AppStorageEnum | CoreStorageEnum;
