import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as RepActions from '../+state/rep.actions';
import { ImagesData, RepPartialState } from '../+state/rep.reducer';
import * as RepSelectors from '../+state/rep.selectors';

@Component({
  selector: 'rep-step3-container',
  templateUrl: './step3.container.html',
})
export class Step3Container {
  imagesData$: Observable<ImagesData>;
  isLoading$: Observable<boolean>;

  constructor(private store: Store<RepPartialState>) {
    this.imagesData$ = this.store.pipe(select(RepSelectors.getImagesData));
    this.isLoading$ = this.store.pipe(select(RepSelectors.isLoading));
  }

  updateImagesData(imagesData: ImagesData) {
    this.store.dispatch(RepActions.imagesDataChanged({ imagesData }));
  }

  goToNextStep() {
    this.store.dispatch(RepActions.submitRep());
  }
}
