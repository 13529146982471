import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'frontend-header-only-layout',
  templateUrl: './header-only-layout.component.html',
  styleUrls: ['./header-only-layout.component.scss'],
})
export class HeaderOnlyLayoutComponent implements OnInit {
  @Input() contentGridColumns: string = '1fr 3fr 1fr';
  @Input() headerHeight: string = '92px';

  constructor() {}

  ngOnInit(): void {}
}
