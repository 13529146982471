import { Injectable } from '@angular/core';
import { Multimedia } from '@frontend/shared/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileUploadService {
  private images: BehaviorSubject<Multimedia[]> = new BehaviorSubject<
    Multimedia[]
  >([]);
  public images$: Observable<Multimedia[]>;

  constructor() {
    this.images$ = this.images.asObservable();
  }

  public clear(): void {
    const curVal = this.images.value;
    curVal.splice(0, curVal.length);
  }

  public addImage(image: Multimedia): void {
    const curVal = this.images.value;
    curVal.push(image);
    this.images.next(curVal);
  }

  public removeImage(image: Multimedia): void {
    let index = -1;
    const curVal = this.images.value;

    for (let file of curVal) {
      if (file.fileName === image.fileName) {
        index = curVal.indexOf(image);
        break;
      }
    }

    if (index > -1) curVal.splice(index, 1);

    this.images.next(curVal);
  }
}
