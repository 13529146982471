import { Injectable } from '@angular/core';
import { Refund } from '@frontend/shared/models';
import { Observable, of, throwError } from 'rxjs';

const mockRefunds: Refund[] = [
  {
    referenceNumber: '123456789',
    registrationNumber: 'AUTOTEST1',
    id: '1',
    amount: 10,
    date: new Date(2020, 10, 25),
  },
  {
    referenceNumber: '123456789',
    registrationNumber: 'GV19 XCZ',
    id: '1',
    amount: 10,
    date: new Date(2020, 11, 14),
  },
];

interface GetRefundResponse {
  refund?: Refund;
  error?: string;
}

@Injectable({ providedIn: 'root' })
export class RefundService {
  constructor() {}

  get(
    referenceNumber: string,
    registrationNumber: string
  ): Observable<GetRefundResponse> {
    return this.getMockRefund(referenceNumber, registrationNumber);
  }

  getMockRefund(
    referenceNumber: string,
    registrationNumber: string
  ): Observable<GetRefundResponse> {
    console.log(`getMockRefund(${referenceNumber}, ${registrationNumber})`);
    if (referenceNumber === '1')
      return of({
        error: 'When a passage has been made you cannot refund the payment.',
      });
    if (referenceNumber === '2')
      return of({
        error:
          'You cannot refund the payment because you’ve already refunded the full amount.',
      });

    let refund = mockRefunds.find(
      (r) =>
        r.referenceNumber === referenceNumber &&
        r.registrationNumber.split(' ').join('').toLowerCase() ===
          registrationNumber.split(' ').join('').toLowerCase()
    );

    if (refund) return of({ refund });

    return throwError(
      'No payment found. Please ensure that you have typed in the correct reference and registration number.'
    );
  }

  submitRefund(id: string): Observable<any> {
    if (mockRefunds.findIndex((refund) => refund.id === id) === -1)
      return throwError('INVALID_REFUND_ID');
    else return of({});
  }
}
