import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaFile, Multimedia } from '@frontend/shared/models';
import { UnsubscriberComponent } from '../../unsubscriber.component';


@Component({
  selector: 'frontend-file-uploader-multi',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderMultiComponent extends UnsubscriberComponent implements OnInit {

  @Input() description: string = null;
  @Input() preloadedFiles: MediaFile[];
  @Input() uploadButtonText: string = '';
  @Input() uploaderText: string = '';
  @Input() fileInputId: string = 'fileInputDrag';
  @Input() multi: boolean = false;
  // max set to zero equals no limit/disabled.
  @Input() maxFileSizeKB: number = 0;
  @Input() maxNumFiles: number = 0;
  @Input() maxTotalFileZiseKB: number = 0;

  @Output() notify: EventEmitter<MediaFile> = new EventEmitter<MediaFile>();
  @Output() remove: EventEmitter<MediaFile> = new EventEmitter<Multimedia>();
  @Output() imagesChanged: EventEmitter<MediaFile[]> = new EventEmitter<MediaFile[]>();

  public activeColor: string = 'green';
  public baseColor: string = '#ccc';

  public dragging: boolean = false;
  public images: Multimedia[] = []; // Was hoping to mark this as obsolete but getter caused infinite loop.

  private readonly filePattern = /.pdf|.jpg|.jpeg|.png|.bmp|.gif*/;
  private loadedImages: MediaFile[] = [];

  constructor() {
    super();
  }

  ngOnInit() {
    this.loadedImages = [...this.preloadedFiles];
    this.images = this.loadedImages.map(f => Multimedia.fromMediaFile(f));
    // Subscribe to own event and update images acordingly.
    this.subscriptions.add(this.imagesChanged.asObservable().subscribe(files => this.images = files.map(f => Multimedia.fromMediaFile(f))));
  }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e: any) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  removeAttachment(event: any, file: Multimedia) {
    event.preventDefault();
    const mediaFile = MediaFile.fromMultiMedia(file);
    this.remove.emit(mediaFile);
    this.loadedImages = this.loadedImages.filter(img => img.fileName !== mediaFile.fileName);
    this.imagesChanged.emit(this.loadedImages);
  }

  handleInputChange(e: any) {
    const files = Array.from<File>(e.dataTransfer ? e.dataTransfer.files : e.target.files);
    for (const file of files) {
      if (this.maxNumFiles > 0 && this.loadedImages.length >= this.maxNumFiles) {
        alert(`Can not upload more than ${this.maxNumFiles} files.`);
        return;
      }

      if (!file.name.toLowerCase().match(this.filePattern)) {
        alert(`File type not supported (${file.name}), allowed file types [.pdf|.jpg|.jpeg|.png|.bmp|.gif]`);
        continue;
      }

      if (this.loadedImages.some(img => img.fileName === file.name)) {
        alert(`File already uploaded (${file.name})`);
        continue;
      }

      if (this.maxFileSizeKB > 0 && file.size > this.maxFileSizeKB * 1024) {
        alert(`File size must be below ${Math.floor(this.maxFileSizeKB / 1024)}MB.`);
        continue;
      }

      if (this.maxTotalFileZiseKB > 0 && this.loadedImages.reduce((sum, f) => sum + f.size, file.size) >= this.maxTotalFileZiseKB * 1024) {
        alert(`Total file size must be below ${Math.floor(this.maxTotalFileZiseKB / 1024)}MB.`);
        continue;
      }

      this.getMediaFile(file, this.description).then(media => {
        this.notify.emit(media);
        this.loadedImages.push(media);
        this.imagesChanged.emit(this.loadedImages);
      });
    }

  }

  private getMediaFile(file: File, description: string): Promise<MediaFile> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        let fileData: MediaFile = new MediaFile(
          file.name,
          e.target.result,
          file.size,
          description
        );
        resolve(fileData)
      };
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  }

}
