<ng-container *ngIf="theme === 'default'">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

<ng-container *ngIf="theme === 'material'">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

<ng-container *ngIf="theme === 'mz'">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10 col-xl-9 col-xxl-8 mt-2">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #content><ng-content></ng-content></ng-template>
