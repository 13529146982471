import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      (request.url.includes("parkiapay.com/adyen") ||
        request.url.includes("claims") ||
        request.url.includes("api.epass24.com") ||
        request.url.includes("token")) &&
      this.auth.isAuthenticated()
    ) {
      request = request.clone({
        setHeaders: {
          Authorization:
            this.auth.getTokenType() + ` ${this.auth.getAuthToken()}`,
        },
      });
    }
    return next.handle(request);
  }
}
