import {
  Directive,
  Input,
  Output,
  EventEmitter,
  Component,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Language } from '@frontend/shared/services';
import { BaseLanguagePicker } from './base-language-picker.component';

@Component({
  selector: 'frontend-language-picker-dropdown',
  templateUrl: './language-picker-dropdown.component.html',
  styleUrls: ['./language-picker-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagePickerDropdownComponent extends BaseLanguagePicker {}
