import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive()
export class UnsubscriberComponent implements OnDestroy {
  public subscriptions = new Subscription();

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
