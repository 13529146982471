<header>
  <div class="header-logo-wrapper" (click)="goTo('HEADER_LOGO_LINK')">
    <img class="header-logo" alt="logo" src="{{ logoSrc }}" />
  </div>
  <span></span>
  <div class="navigation">
    <button style="display:none;" class="m-show" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu" style="display:none;" class="m-show">
      <button mat-menu-item *ngFor="let menuItem of menuItems" (click)="goTo(menuItem.href)">
        {{ menuItem.text | translate }}
      </button>
    </mat-menu>
    <div style="display:block;" class="m-hide">
      <button mat-flat-button *ngFor="let menuItem of menuItems" (click)="goTo(menuItem.href)">
        {{ menuItem.text | translate }}
      </button>
   </div>
  </div>
</header>