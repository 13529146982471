import {Component} from '@angular/core';

@Component({
  selector: 'pp-spinner',
  styleUrls: ['./spinner.component.scss'],
  template: `
    <div class="spinner-loader"></div>
  `,
})
export class SpinnerComponent {

}
