<ng-container *ngIf="theme === 'default'">
  <p><ng-container *ngTemplateOutlet="content"></ng-container></p>
</ng-container>

<ng-container *ngIf="theme === 'material'">
  <p><ng-container *ngTemplateOutlet="content"></ng-container></p>
</ng-container>

<ng-container *ngIf="theme === 'mz'">
  <p class="text-danger">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </p>
</ng-container>

<ng-template #content><ng-content></ng-content></ng-template>
